@import "variables";
@import "mixins";

.list-container {
    list-style-type: none;
    padding-left: 0;
    margin-top: spacing(1.3);

    .flex-container {
        display: flex;
        align-items: center; // Centers items vertically within the container
        justify-content: flex-start; // Aligns items to the start of the container

        .bullet-point {
            color: black;
            padding-right: spacing(1);
            padding-bottom: spacing(1);
            font-size: 60px;
            align-self: center; // Centers the bullet point itself vertically
        }

        .content {
            display: flex; // Uses flex to align content
            align-items: center; // Centers items vertically within the content div
            justify-content: flex-start; // Aligns items to the start of the content div

            p {
                margin: 0;
                padding-right: spacing(1.5);
                font-size: 18px;

                &:last-child {
                    padding-left: spacing(1.5);
                    font-size: 24px;
                    padding-bottom: spacing(0.8);
                }
            }
        }
    }
}
