@import "mixins";
@import "variables";

.create-diagnoses-free-text {
    margin-top: 10px;
}

.diagnose-free-text {
    margin-left: 15px;
}

.new-management-outcome-input .ui.input {
    width: 100%;
}

.review-management-outcome-checkbox {
    padding-left: 3px;
    padding-top: 3px;
}

.ui.grid .column.review-management-outcome-trash {
    padding-right: 3px;
}

.ui.grid .column.diagnosis-edit-display-order {
    margin-left: spacing(5);
    margin-top: spacing(1);
}

.ui.grid .row.title {
    padding-top: 40px;
}

.tab-button-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 185px;
}

.clear-filter-button.ui.button {
    margin-top: 23px;
}

.upload-logo-text {
    margin-top: 10px;
    margin-bottom: -0;
}

.ui.button.remove-organisation-logo {
    margin-top: 10px;
}

.organisation-logo-wrapper {
    width: 125px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ui.form .field.create-review-rich-text-label {
    margin-bottom: 0px;
    padding-top: 10px;
}

.ui.form .field.new-management-outcome-discharge-toggle {
    margin-top: spacing(1);
    margin-bottom: spacing(2);
}

.admin-user-list {
    .ui.selection.dropdown {
        min-width: auto;

        .default.text {
            white-space: nowrap;
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}

.inherited-warning.icon.exclamation.triangle {
    color: #ffa500;

    &.details.organisation {
        margin-left: 5px;
        padding-top: 0px;
    }

    &.popup {
        margin-left: 10px;
    }
}

.row .column .user-details-select {
    .ui.multiple.dropdown > .label {
        border: unset;
        line-height: spacing(2.25);
        padding: spacing(0.5) spacing(1.25);
        border-radius: spacing(2);
        box-shadow: unset;
        background-color: $gray-background;
        font-size: spacing(1.75);
        font-weight: 400;
        color: $black;
        vertical-align: initial;

        & > .delete.icon {
            height: spacing(2);
            width: spacing(2);
            font-size: spacing(1.25);
            border-radius: 50%;
            background-color: $black;
            color: $gray-background;
        }
    }

    &.visible.dropdown {
        z-index: 12;
    }
}
.ui.selection.dropdown {
    min-width: auto;

    &.visible {
        z-index: 11;
    }

    .text {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &.new-personal-data-field {
        min-width: 300px;
        margin-right: spacing(2);
    }
}

.title-divider.export-csv-grid-bottom-padding {
    padding-bottom: 0px;
}

.ui.grid {
    > .row.export-csv-grid-bottom-padding {
        padding-bottom: 0px;
    }

    > .row.export-csv-no-top-padding {
        padding-top: 0px;

        > .export-csv-column {
            flex: 1;
        }
    }

    > .row.export-csv-grid-top-padding {
        padding-top: 20px;
    }

    .vertical.menu .menu-item-home {
        margin-top: 50px;
    }
}

.ui.button.export-csv-button {
    margin-top: 22px;
    height: 42px;
}

.overdue-wrapper {
    padding: 3rem 0;

    .ui.table {
        @media screen and (min-width: 1200px) {
            width: 600px;
        }
    }
}

.overdue-checkboxes {
    padding-top: 48px;
}

.overdue-checkbox {
    height: 90px;
    display: flex;
    align-items: center;
}

.ui.button.overdue-button {
    margin-top: 1rem;
}

.overdue-row {
    .ui.form .fields {
        margin: 0;
        align-items: center;
    }

    .ui.form .four.wide.field:first-child {
        padding-left: 0;
    }
}

.overdue-time {
    text-align: center;
}

.notification-email {
    position: relative;
    display: flex;

    .error.field {
        .ui.label {
            position: absolute;
            top: 100%;
            left: 0;
            text-align: center;
            z-index: 100;
        }
    }
}

.ui.icon.button.notification-email-button {
    margin-left: 10px;

    i {
        font-size: 0.75em;
    }
}

.notification-email-list {
    padding-top: 2em;
}

.notification-checkboxes {
    display: flex;

    .field {
        margin-right: 10px;
    }
}

.notification-buttons {
    display: flex;
    justify-content: flex-end;
}

.notification-copied-from-parent p {
    display: flex;
}

.ui.grid.admin-page__layout {
    margin: 0;
}

.ui.form.create-diagnosis-form {
    max-width: 330px;
}

.ui.floated.header.edit-button-personal-data {
    margin-right: 0;

    .ui.button {
        margin-right: 0;
    }
}

.allocation-disabled {
    color: $weathered-stone;
}

.allocation-configuration-table {
    .allocation-configuration-table-row {
        height: spacing(6);
        padding: spacing(1);
    }

    .allocation-dropdown {
        .ui.dropdown {
            height: 8px;
        }
    }

    .urgent-refer-toggle {
        padding-left: spacing(1);
    }
}

a.ui.teal.button.dark-button.create-user-btn {
    color: rgba(0, 0, 0, 0.6);
    background-color: #e0e1e2;
    cursor: pointer;
    display: inline-block;
    min-height: 1em;
    position: relative;
    padding: spacing(1) spacing(2.5);

    &:hover,
    &:focus {
        background-color: #cacbcd;
        color: #000;
    }
}

.field-sub-question-row {
    padding-top: 0px !important;

    .field-sub-question {
        padding-left: spacing(6) !important;

        &:before {
            border-left: solid 1px $black;
            border-bottom: solid 1px $black;
            position: absolute;
            transform: translate(-26px, -7px);
            content: "";
            width: spacing(3);
            height: 20px;
        }
    }
}

.row-response-options {
    display: flex;
    flex-direction: row;
    align-items: center;

    .column {
        max-width: 100%;
    }
}

.structured-template-creation {
    width: 100%;

    .sub-question {
        padding-left: spacing(3) !important;

        &:before {
            border-left: solid 1px $black;
            border-bottom: solid 1px $black;
            position: absolute;
            transform: translate(-16px, 4px);
            content: "";
            width: spacing(3);
            height: 20px;
            border-radius: 0 0 0 10px;
        }
    }

    .centred-button {
        display: flex;
        justify-content: center;
        margin: spacing(3) auto;
    }

    .checkbox {
        display: flex;
        align-items: center;
        padding-right: spacing(1);

        label {
            font-size: 12px;
            font-weight: 300;
        }
    }

    .ui.checkbox {
        margin-top: 0;
    }

    .ui.selection.dropdown {
        height: spacing(4.75);
    }

    .options-tag-input {
        padding-left: spacing(1);
        width: 37%;

        .MuiAutocomplete-inputRoot {
            border: 1px solid rgba(34, 36, 38, 0.15);

            input {
                border: 0;
            }

            &:before,
            &:hover:before {
                border-bottom: none;
            }

            .MuiInputBase-input {
                width: 50px;
            }
        }

        &--child {
            @extend .options-tag-input;
            padding-left: 24px;
        }
    }

    .buttons-container {
        display: flex;
        align-items: center;
        font-size: 12px;
        i {
            width: 12px;
            height: 12px;
        }

        button {
            border: none;
            margin: 0 spacing(0.5);
            font-weight: 300;
            font-size: 12px;
        }
    }
}

.template-header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
        font-size: 12px;
        opacity: 0.7;
    }
}

.discharge-label.ui.label {
    background-color: #3949ab;
    color: white;
}

.warning-modal {
    img {
        background-color: rgba(255, 177, 32, 0.25);
        border-radius: 50%;
        height: spacing(8.25);
        width: spacing(8.25);
        padding: spacing(1.75);
        overflow: visible;
    }

    .MuiButton-containedPrimary,
    .MuiButton-containedPrimary:hover,
    .MuiButton-containedPrimary:active,
    .MuiButton-containedPrimary:focus {
        color: $black;
        background-color: $warning-primary;
        text-transform: none;
    }

    .MuiButton-outlinedPrimary,
    .MuiButton-outlinedPrimary:hover,
    .MuiButton-outlinedPrimary:active,
    .MuiButton-outlinedPrimary:focus {
        color: $warning-primary;
        border-color: $warning-primary;
    }

    .MuiButton-containedSecondary,
    .MuiButton-containedSecondary:hover,
    .MuiButton-containedSecondary:active,
    .MuiButton-containedSecondary:focus {
        color: $black;
        background-color: $warning-secondary;
        text-transform: none;
    }

    .MuiButton-outlinedSecondary,
    .MuiButton-outlinedSecondary:hover,
    .MuiButton-outlinedSecondary:active,
    .MuiButton-outlinedSecondary:focus {
        color: $warning-primary;
        border-color: $warning-primary;
    }
}

.ui.mini.message {
    padding: spacing(1.25) spacing(0.75);
    font-size: 12px;
    line-height: spacing(2);
}

.organisation-setting-help-text {
    color: $gray;
}

.ui.form .field > label {
    font-size: 1rem;
    font-weight: 400;
}

.landing-page {
    > div {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;

        h2 {
            font-size: 20px;
        }
    }

    h3 {
        font-size: 16px;
        text-decoration: underline;
    }
    section {
        margin-top: 20px;
    }

    .DraftEditor-root {
        font-family: Roboto, sans-serif;
    }

    .MuiOutlinedInput-root {
        & .MuiOutlinedInput-notchedOutline {
            border-color: rgba(0, 0, 0, 0.23);
        }

        &:hover .MuiOutlinedInput-notchedOutline {
            border-color: rgba(0, 0, 0, 0.23);
        }

        &.Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color: rgba(0, 0, 0, 0.23);
            border-width: 1px;
        }
    }

    .button-container {
        justify-content: center;
    }
}
