@import "variables";
@import "mixins";

.fatal-error-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 0 spacing(2.5);
    text-align: center;
    line-height: 1.1;

    iframe {
        display: none;
    }

    span {
        margin: spacing(2.5) 0;
        font-weight: bold;
        font-size: 24px;

        @include tablet {
            font-size: 28px;
        }
    }

    p {
        font-size: 16px;

        @include tablet {
            font-size: 14px;
            max-width: spacing(93);
        }
    }
}
