@import "mixins";

.field.login {
    width: 300px !important;

    @include max-mobile {
        width: auto !important;
    }
}

.field.patient {
    width: 450px !important;
}

.field.forgot-email {
    margin: spacing(2) 0;
}

.password-matching-icon {
    min-width: 20px;
}

.normal {
    .ui {
        max-width: 120px;
    }
}

.personal-type {
    .ui.dropdown {
        max-width: 100px;
        min-width: 100px;
    }
}

.validation-type {
    .ui.dropdown {
        min-width: 100px;
        max-width: 135px;
        width: 100%;
    }
}

.field.small .ui.input {
    width: 50%;
}

.checkbox {
    .ui {
        margin-top: 7px;
    }
}

.ui.grid > .row.option-row {
    flex-wrap: nowrap;

    &.option-row__mapped {
        margin-top: 10px;

        & + * {
            margin-top: 0;
        }
    }

    &:not(.option-row__mapped) {
        .option-row__icon {
            margin-top: 10px;
        }
    }
}

.type-options {
    &:after {
        content: ", ";
    }

    &:last-child:after {
        content: ".";
    }
}

.input-fixed-width .ui.input {
    margin-top: spacing(1);
    width: spacing(12);
}

.medium-select-box {
    .ui.selection.dropdown {
        width: 250px;
    }
}

.input-info-message {
    font-size: 12px;
    opacity: 60%;
    margin: spacing(-1.25) 0 spacing(1.25) 0 !important;
}
