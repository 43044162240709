@import "variables";
@import "mixins";

.reset-password-modal {
    .MuiDialog-paperWidthSm {
        min-width: spacing(37.5);
        padding: spacing(2.5) spacing(3) spacing(4.25);

        @include tablet {
            padding: spacing(4.25) spacing(6.25) spacing(5.75);
        }
    }

    .MuiDialogContent-root {
        padding-top: 0px;
        padding-bottom: 0px;
        padding: 0px;
    }

    .MuiDialogActions-root {
        padding-bottom: spacing(2.5);
        padding: spacing(1.25) 0px 0px;
        justify-content: flex-start;
    }

    h2 {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 18px;
        margin-bottom: 0px;
        font-weight: 700;
        margin-bottom: spacing(2.5);

        @include tablet {
            font-size: 20px;
        }
    }

    i {
        position: absolute;
        color: $black;
        cursor: pointer;
        top: 0px;
        right: 0px;
        margin-right: 0px;
    }

    .modal-close-icon {
        margin: 0;
        padding: 1em;
        background-color: transparent;
        border: 0;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;

        img {
            width: spacing(3);
            height: spacing(3);

            @include tablet {
                width: spacing(4);
                height: spacing(4);
            }
        }
    }

    &__subtitle {
        width: spacing(40);

        @include mobile {
            width: 100%;
        }
    }
}
