@import "mixins";

.custom-alert-dialog {
    margin-top: spacing(30);
    max-width: spacing(80);
    max-height: spacing(80);
    margin-bottom: 128px;

    font-family: Arial, Helvetica, sans-serif;
    width: spacing(50);
    padding: spacing(4);
    text-align: left;
    background: $white;
    border-radius: spacing(1);
    box-shadow: 0 spacing(3) spacing(9) rgb(0 0 0 / 13%);
    color: $dark-grey;

    > h1 {
        margin-bottom: spacing(4);
    }
}

.custom-alert-dialog > * {
    margin-top: spacing(4);
}

.custom-alert-dialog > button {
    outline: none;
    background: $dark-grey;
    border: none;
    display: inline-block;
    padding: spacing(1) spacing(2);
    color: $white;
    margin-right: spacing(1);
    border-radius: spacing(0.5);
    font-size: 12px;
    cursor: pointer;
}
