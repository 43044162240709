@import "mixins";

.ui.form .field.questionary-item {
    margin: 0;

    @include max-mobile {
        margin-left: 20px;
    }
}

.wizzard-container {
    .ui.form textarea {
        resize: auto;
        max-width: 500px;
        max-height: 500px;
    }
}

.asterisk {
    color: red;
}

.short-note-lession-location {
    width: 305px;
}

.lesion-select {
    > .ui.selection.dropdown > .text {
        height: 15px;
    }
}

.short-note-lesion-nsc-location {
    width: 305px;
    padding-top: 19px;
    > .ui.input {
        width: 305px;
    }
}

.short-note-lesion-nsc-complaint {
    width: 305px;
    padding-top: 23px;
    > .ui.input {
        width: 305px;
    }
}

.ui .form .questionary-response-row {
    display: flex;
    margin-top: 10px;
}

.ui.grid > .row .update-exclusions {
    padding-right: 0px;

    .questionary-response-row {
        width: 90%;
        max-width: 900%;
    }

    .questionary-response-options {
        width: 90%;
        max-width: 900%;
        .ui.grid > .row > .column {
            padding-right: 0px;
        }
    }
}

.questionary-response-options {
    width: 400px;

    @include max-mobile {
        display: flex;
        flex-direction: column;

        & .questionary-item {
            margin-left: 20px;
        }
    }
}

#location-error-message {
    scroll-margin: 120px;
}

.form-field-tip {
    color: #444;
    font-style: italic;
}

.questionary-wrapper {
    padding-top: 10px;
}

.questionary-item {
    overflow-wrap: break-word;

    .ui.checkbox {
        @include max-mobile {
            max-width: 80%;
        }
    }
}

.questionary-item-remote {
    margin-left: 20px;
    font-size: 16px;

    .ui.checkbox label {
        font-size: 16px;
    }

    .ui.form .field.questionary-item {
        margin-top: 10px;
    }

    @include max-mobile {
        margin-left: 0;
    }

    .questions-optional-information {
        width: 50%;

        @include max-mobile {
            margin-top: 10px;
            width: 100%;
        }
    }
}

.notes-wrapper {
    display: inline-block;
}
