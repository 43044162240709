@import "variables";
@import "mixins";

.ui.table.assessment-table {
    margin-top: 20px;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    thead {
        border-top-color: white;
        th {
            border-top: 0px;
            border-left: 0px;
            border-right: 0px;
            border-top-color: white;
            background-color: white;
        }
    }

    tbody {
        tr td {
            border-right: 1px solid #dededf !important;
            border-left: 0px;
        }
    }

    .item {
        td {
            &.collapsing.two.wide.assessment-main-cell {
                background-color: $table-cyan;
            }
        }
    }
}

.four.wide {
    word-break: break-word;
}

.ui.table.assessment-summary-table {
    word-break: break-word;
    thead tr th {
        background-color: $table-cyan;
    }
}

.table-label {
    height: 45px;
    div {
        margin-top: 8px;
    }
}

.horizontal-scroll {
    overflow-x: auto;

    .ui.table.additional-bottom-margin {
        margin-bottom: spacing(22);
    }
}

.list-table {
    overflow-x: auto;
}

.organisation-table-label {
    height: 45px;

    .detail {
        margin-top: 8px;
        margin-right: 1em;
    }
}

.contact-text {
    height: 100%;
    display: flex;
    align-items: center;
}

.lesion-table-max-width {
    h4 {
        max-width: 800px;
        overflow-wrap: break-word;
    }
}

.ui.table.case-review__tracking-table {
    thead tr th {
        background-color: $table-cyan;
    }

    .case-review {
        &__tracking-table-editable-field {
            display: flex;
            align-items: center;
            height: auto;
            line-height: 38px;

            .ui.button {
                width: 72px;
            }
        }

        &__tracking-table-editable-field-action-wrapper {
            display: flex;
            align-items: center;
        }

        &__tracking-table-render {
            display: inline-block;
            margin-bottom: 0px;
            line-break: anywhere;
            width: 100%;
        }

        &__tracking-table-input {
            display: inline-block;
            margin-bottom: 0px;
            line-break: anywhere;
            width: 100%;

            .ui.input {
                padding: 0px;

                > input {
                    width: inherit;
                    box-sizing: border-box;
                }
            }
        }
    }
}

.ui.table td.hospital-number-label {
    padding: spacing(5) spacing(1) spacing(1);
    white-space: nowrap;

    .patient-search-results__item-label {
        margin-top: spacing(1);
    }

    &s {
        padding: spacing(9) spacing(1) spacing(1);

        .patient-search-results__item-label {
            margin-top: spacing(1);
        }
    }
}
