@import "variables";
@import "mixins";

.ui.container.login-container {
    flex: 1;
    display: flex;
    align-items: center;
    width: spacing(60);
    margin: spacing(5) 0;

    & > * {
        width: 100%;
        padding: spacing(2.5) spacing(3);
    }

    .ui.header {
        margin-top: 0;
    }

    .actions-container {
        display: flex;
        justify-content: space-between;

        .forgotten-password {
            margin-left: spacing(2);
            text-align: right;
            text-decoration: underline;
            color: $dark-grey;
            cursor: pointer;
        }
    }
}

.expired_token_message {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;

    h2 {
        margin-bottom: spacing(4);
    }

    p {
        margin-bottom: spacing(4);
    }
}

.services_blocked_message {
    @extend .expired_token_message;
    p {
        text-align: center;
    }
}
