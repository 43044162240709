//breakpoints
$mobile: 460px;
$tablet: 768px;
$small-desktop: 1200px;
$desktop: 1280px;
$hidpi: 1440px;
$widescreen: 1600px;

//colours
$colour-primary: #7cd8d1;
$colour-secondary: #e0f3f2;

$warning-primary: #ffb020;
$warning-secondary: #fff4e5;

$table-cyan: #e0f3f2;
$isle-royale: #7cd8d1;
$verdigris: #3cb5ad;
$resonant-blue: #03a1c9;
$mid-blue: #2f80ed;
$cornflower-blue: #4183c4;
$light-red: #ff7a7a;
$guardsman-red: #c90002;
$error-font-smoked-red: #9f3a38;
$error-color: #ff0202;
$yellow: #ffc040;
$light-yellow: #ffd175;
$white: #ffffff;
$almost-white: #fff6f6;
$dr-white: #fafafa;
$dark-white: #f5f5f5;
$mercury: #e1e1e1;
$porpoise: #dadada;
$weathered-stone: #c4c4c4;
$mid-grey: #999999;
$dark-grey: #7a7a7a;
$light-black: #373f41;
$black: #000000;
$table-header: #7cd8d120;
$astral: #2e76b6;
$gray: #8a8a8a;
$bright-gray: #394150;
$shark: #1b1c1d;
$error-background: #fdf6f6;
$error-background-darker: #fdeded;
$gray-background: #f0f0f0;
$dark-gray-background: #d3d3d3;
$gray-border: #dbdcdd;
$modal-warning: #ffb020;
$cherry-tree: #e0b4b4;
$info-background-color: #e5f6fd;
$info-text-color: #014361;

//spacing
$spacer-xs: 4px;
$spacer-s: 8px;
$spacer-m: 16px;
$spacer-l: 32px;

//fonts
$icons-font-family: "Icons";
