@import "variables";
@import "mixins";

.ui.button {
    &.fixed-width {
        width: spacing(27);
    }

    &.hidden {
        display: none;
    }

    &.margin-top-30 {
        margin-top: 30px;
    }

    &.empty-button,
    &.dark-button,
    &.empty-dark-button {
        background: transparent;
        border-radius: 5px;
        margin-bottom: spacing(1);
        text-align: center;
    }

    &.dark-button,
    &.empty-dark-button {
        border: 1px solid #999;
        color: $black;
        line-height: 20px;
    }

    &.empty-button {
        border: 2px solid $isle-royale;
        color: $isle-royale;

        &:hover,
        &:focus {
            background-color: $verdigris;
            color: $dr-white;
        }
    }

    &.dark-button {
        background: $porpoise;
        border: none;

        &:hover,
        &:focus {
            background-color: $mid-grey;
            color: $dr-white;
        }
    }

    &.filled-button {
        background: $isle-royale;
        border: 2px;
        border-radius: 5px;
        color: $black;
        margin-bottom: spacing(1);
        text-align: center;
        line-height: 20px;

        &:hover,
        &:focus {
            background-color: $verdigris;
        }
    }

    &.cancel-button {
        background: $colour-secondary;
        border: 2px;
        border-radius: 5px;
        color: $black;
        margin-bottom: spacing(1);
        text-align: center;
        line-height: 20px;

        &:hover,
        &:focus {
            background-color: $verdigris;
        }
    }

    &.warning-button {
        background: $yellow;
        border: 2px;
        border-radius: 5px;
        color: $black;
        margin-bottom: spacing(1);
        text-align: center;
        line-height: 20px;

        &:hover {
            background-color: $light-yellow;
        }
    }

    &.logout-button {
        border: 0px;
        background-color: transparent;
    }

    &.admin-button {
        border: 0px;
        background-color: transparent;
    }

    &.btn-upload-csv-file {
        position: relative;
        padding: 0;

        label {
            display: block;
            width: spacing(20);
            padding: 0.78571429em 1.5em 0.78571429em;
            white-space: nowrap;
            cursor: pointer;
        }

        input {
            width: 0.1px;
            height: 0.1px;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            z-index: -1;
        }
    }

    &.remote-consent-navigation {
        &__back {
            @include max-mobile {
                order: 2;
                min-width: spacing(27);
            }
        }

        &__continue {
            @include max-mobile {
                order: 1;
                min-width: spacing(27);
            }
        }
    }
}
