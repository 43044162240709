@import "variables";
@import "mixins";

.patient-banner {
    &__table.ui.table td {
        border-top: none;
    }
}

.patient-from-integration {
    &__wrapper {
        display: flex;
        margin: 0;
    }

    &__name.ui.label {
        background-color: $astral;
        font-weight: bold;
        color: $white;
        margin-right: spacing(1);

        div {
            display: flex;
            align-items: center;

            svg {
                margin-left: spacing(0.5);
            }
        }
    }

    &__icon {
        margin-right: spacing(1);
    }

    &__sync-date {
        font-weight: bold;
        display: flex;
        align-items: center;
    }
}

.patient-banner-header {
    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__labels-wrapper {
        display: flex;

        .patient-search-results__item-label:nth-child(2) {
            margin-left: spacing(1);
        }
    }
}

.ui.table .patient-banner-table-cell {
    &__main {
        background-color: $table-cyan;
        word-wrap: break-word;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
    }

    &__value {
        font-weight: bold;
        margin-top: spacing(1);
        word-break: break-word;
        min-height: spacing(3);
    }

    &__empty {
        border-left: none;
    }
}

.background-white {
    background-color: $white;
}
