.error-message {
    &.ui.message {
        text-align: left;

        ul,
        ol {
            li:only-child {
                margin-left: -15px;
                &::marker {
                    content: "";
                }
            }
        }
    }
}
