@import "mixins";
@import "variables";

$light-blue-border-select: #85b7d9;
$almost-white: #fff6f6;
$error-light-red: #e0b4b3;
$base-border-gray: #e2e2e2;
$base-border-gray-disabled: #e0e0e2;
$required-asterix-red: #db2828;
$error-font-smoked-red: #9f3a38;
$label-gray-disabled: #989898;

$border-size: 1px;

.MuiFormLabel-root.input-date-field {
    &.label {
        color: $black;
        font-size: 0.92857143em;
        margin: 3px 0 0.6rem 0;
        &.bold-label {
            font-weight: 700;
        }
        &.Mui-disabled {
            color: $label-gray-disabled;
        }
    }
}

.input-date-field-date-picker {
    width: 100%;
    div.Mui-error,
    .Mui-error.MuiInputBase-formControl,
    .Mui-error.Mui-focused {
        background-color: $almost-white;
        border-width: $border-size;
        .MuiOutlinedInput-notchedOutline {
            border-color: $error-light-red;
        }
        input.MuiInputBase-input {
            background-color: $almost-white;
            border-color: $error-light-red;
        }
    }
    .MuiTouchRipple-root span {
        animation: none;
    }
    p.Mui-error.MuiFormHelperText-root {
        background: $white !important;
        border: $border-size solid $error-light-red !important;
        color: $error-font-smoked-red !important;
        border-radius: 0.28571429rem;
        line-height: 1;
        width: fit-content;
        font-weight: 700;
        margin: 0 0.14285714em;
        padding: 0.5833em 0.833em;
        font-size: 0.85714286rem;
        font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
            Droid Sans, Helvetica Neue, sans-serif;
    }

    &:hover fieldset.MuiOutlinedInput-notchedOutline {
        border-width: $border-size;
        border-color: $base-border-gray;
    }

    div {
        height: 38px;
        input {
            height: 17px;
        }
        div.MuiInputAdornment-root {
            button {
                padding: 8px;
                margin-right: -12px;
            }
        }
        &.Mui-disabled .MuiOutlinedInput-notchedOutline {
            border-color: $base-border-gray-disabled;
        }
        .MuiOutlinedInput-notchedOutline {
            border-width: $border-size;
            border-color: $base-border-gray;
        }
        &:focus {
            border-color: $base-border-gray;
        }
    }

    div.Mui-focused {
        border-width: $border-size;
        border-color: $light-blue-border-select;
        .MuiOutlinedInput-notchedOutline {
            border-width: $border-size;
            border-color: $light-blue-border-select;
        }
        input.MuiInputBase-input {
            border-width: $border-size;
            background-color: $almost-white;
            border-color: $light-blue-border-select;
        }
    }
}

.MuiInputLabel-asterisk {
    color: $required-asterix-red;
}
