@import "mixins";
@import "variables";
.custom-select-container {
    margin-top: spacing(0.75);

    label {
        margin-bottom: spacing(0.625);
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
            "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
        &.MuiFormLabel-root {
            color: #000000de;
            margin-bottom: spacing(0.5);

            &.error {
                color: $error-font-smoked-red;
            }
        }
    }
    .custom-select {
        &.MuiOutlinedInput-root {
            width: 100%;

            &:hover .MuiOutlinedInput-notchedOutline {
                border: 1px solid rgba(34, 36, 38, 0.35);
            }

            &.Mui-focused .MuiOutlinedInput-notchedOutline {
                border: solid 1px $black;
            }

            .MuiOutlinedInput-notchedOutline {
                border: 1px solid rgba(34, 36, 38, 0.15);
            }
        }

        .MuiSelect-select {
            padding: spacing(1.5) spacing(3) spacing(1.5) spacing(1.5);
            background-color: $white;

            &:focus {
                background-color: unset;
            }
        }

        em {
            font-style: normal;
        }

        &.error {
            background-color: $almost-white;
            color: $error-font-smoked-red;

            em {
                opacity: 100% !important;
            }
        }
    }

    .MuiFormHelperText-root.Mui-error {
        margin-top: spacing(1.5);
        border: 1px solid #e0b4b4;
        color: $error-font-smoked-red;
        padding: spacing(1) spacing(1.25);
        border-radius: 5px;
        font-size: 12px;
        font-weight: 700;
    }
}
