@import "mixins";
@import "variables";

.ui.stackable.menu {
    min-height: 58px;
    max-height: 58px;

    .logo {
        max-height: 40px;
    }

    .item.cases-tab-header,
    .admin-tab-header {
        height: 100%;
    }

    .cases-tab-header {
        font-weight: bold;
    }

    .active-header-tab {
        .item {
            background: #f2fbfa;
            border-bottom: 3px solid #94d5d0;
            :first-child {
                padding-top: 3px;
            }
        }
    }

    .user-header-section {
        min-width: max-content;
        border-left: 1px solid $gray-border;

        &:hover {
            cursor: auto;
            background: none;
        }

        .ui.grid .row {
            .user-icon-column {
                width: 44px;
            }

            .user-data-column {
                width: max-content;
            }

            .user-data-grid {
                .title {
                    padding-bottom: 0;
                    padding-top: 10px;

                    .ui.header {
                        color: gray;
                    }

                    .column {
                        padding-left: 0px;
                    }
                }

                .data {
                    padding-top: 0px;

                    .column {
                        padding: 0px;
                    }
                }
            }

            .dropdown {
                padding-top: 8px;
            }
        }

        &::before {
            content: none;
        }
    }

    .user-header-dropdown {
        &::before {
            content: none;
        }
    }

    .header-single-menu-item {
        border-left: 1px solid $gray-border;
        cursor: pointer;
        min-width: fit-content;
    }

    .header-single-menu-item-text {
        margin-left: -25px;
        font-weight: bold;
        display: flex;
        align-items: center;
    }

    .header-additional-margin {
        margin-left: 10px;
        margin-right: -30px;

        .user-data-column {
            line-height: 20px;
        }
    }

    .right.menu.header-right-section {
        .item {
            &::before {
                content: none;
            }
        }

        @include max-mobile {
            display: none;
        }
    }

    .header-right-section-mobile {
        display: none;
    }

    @include max-mobile {
        flex-direction: row;

        .item {
            width: auto !important;
        }

        .header-right-section-mobile {
            margin-left: auto;
            display: flex;
            flex-direction: row;
        }
    }
}

.user-menu-container,
.organisation-menu-container {
    display: flex;
    align-items: center;
    border-left: 1px solid $gray-border;

    .MuiButton-root:hover {
        background: none;
    }

    .ui.image,
    .user-icon {
        margin-left: spacing(3);
        margin-right: spacing(1);
    }

    .user-menu-details-container,
    .organisation-menu-details-container {
        font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
        font-weight: bold;
        font-size: 14px;
        text-transform: capitalize;
        letter-spacing: normal;
        display: flex;
        align-items: center;

        .user-menu-user-info,
        .organisation-menu-organisation-info {
            line-height: 1;

            h5 {
                margin-bottom: 0;
                text-align: left;
                color: grey;
                font-size: 14px;
            }
        }

        i:before {
            font-size: 20px;
            padding-left: spacing(2);
            margin-right: spacing(3.5);
        }
    }

    .user-menu-icon-button,
    .organisation-menu-icon-button {
        padding-left: spacing(0.25);

        &.Mui-focusVisible {
            border: solid 0.5px $black;
        }
    }

    .single-item {
        margin-right: spacing(3);
    }
}

.user-menu-items {
    .MuiMenuItem-root {
        font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
    }
}

.organisation-menu-items {
    .MuiMenuItem-root {
        padding: spacing(2);
        margin: spacing(1) spacing(2.5);
        min-width: spacing(40);
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: spacing(1.75);
        padding: 0;
        border: 1px solid $gray-border;
        background-color: $gray-background;
        border-radius: spacing(0.5);
        min-height: spacing(8);

        p {
            padding-left: spacing(2);
            color: $black;
            font-weight: 400;
            font-size: spacing(1.75);
        }

        .ui.image {
            display: none;
            width: spacing(4);
            height: spacing(4);
            margin-right: spacing(2);
        }

        &:hover,
        &:focus {
            background-color: $dark-gray-background;
            cursor: pointer;
        }

        &.selected {
            background-color: $colour-secondary;

            .ui.image {
                display: unset;
            }
        }
    }
}
