@import "mixins";

.step-indicator {
    position: relative;
    margin-bottom: spacing(6);

    .step-count {
        font-size: 16px;
        font-weight: 600;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    .back-button {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 12px;
    }

    .skip-button {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 12px;
    }
}
