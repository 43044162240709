@import "variables";
@import "mixins";

.patient-details {
    &__grid {
        margin: 8px !important;
    }

    &__header {
        display: flex;
        justify-content: space-between;
    }

    &__details {
        border-bottom: 1px solid lightgrey;
        min-height: 38px;
        padding-top: 1rem;
        padding-bottom: 0px !important;
    }

    &__detail {
        font-weight: 600;
        min-height: 28px;
        padding-bottom: 14px;

        &:not:last-child {
            border-bottom: 1px solid lightgrey;
        }
    }

    &__line-wrapper {
        word-wrap: anywhere;
    }
}

.patient-lookup {
    margin: auto 0;
    h3 {
        text-align: center;
    }

    .icon {
        background-color: $colour-primary;
        border-radius: 50%;
        width: 52px;
        height: 52px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }

    .wizzard-container {
        padding: 40px;
        border-radius: 15px;
        width: 520px;
    }

    .pds-loading {
        text-align: center;

        span {
            margin-right: spacing(2.5);
            color: $colour-primary;
        }
    }

    .patient-search {
        h3 {
            font-weight: 700;
            font-size: 20px;
        }

        form {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            padding: 0 0 spacing(2.5) 0;

            .input-date-field-date-picker {
                .Mui-error {
                    background-color: $white;

                    .MuiOutlinedInput-notchedOutline {
                        border: solid 1px $error-color;
                    }

                    &.Mui-focused .MuiOutlinedInput-notchedOutline {
                        border: solid 2px $error-color;
                    }
                }

                div {
                    height: unset;

                    .MuiOutlinedInput-notchedOutline {
                        border-color: #0000003b;
                    }

                    &.Mui-focused input.MuiInputBase-input {
                        background-color: unset;
                    }

                    &.Mui-error.MuiInputBase-formControl input.MuiInputBase-input {
                        background-color: $white;
                    }

                    &.Mui-focused .MuiOutlinedInput-notchedOutline {
                        border: solid 2px $colour-primary;
                    }
                }
            }

            .input-error {
                .MuiOutlinedInput-root {
                    fieldset {
                        border: solid 1px $error-color;
                        border-radius: 5px;
                    }

                    .MuiSelect-root {
                        border: solid 1px $error-color;
                        border-radius: 5px;
                    }
                }

                .date-picker {
                    .MuiOutlinedInput-root {
                        input {
                            border: unset;
                            border-radius: 5px;
                        }
                    }

                    fieldset {
                        border: solid 1px $error-color;
                    }
                }
            }

            .form-fields {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            .errors {
                color: $error-color;
                padding: spacing(0.625) 0 0 spacing(0.25);
                font-size: 14px;
                margin-bottom: 0;
            }

            label {
                padding: spacing(1.25) 0;
                font-weight: 600;
                color: $black;
            }

            input {
                padding: spacing(1.5) spacing(1.75);
            }

            .MuiSelect-root {
                padding: spacing(1.5) spacing(4) spacing(1.5) spacing(1.75);
                span {
                    color: #00000066;
                }
            }

            .MuiFormLabel-root {
                font-size: 14px;
                font-weight: 600;
                margin: 0;
            }
        }

        .full-width-button {
            width: 100%;
            text-decoration: none;
            text-transform: unset;
        }

        .MuiAlert-root {
            font-family: "Lato";
            font-size: 16px;
        }

        a {
            text-decoration: underline;
        }

        .combination-control {
            text-align: center;
            font-family: "lato";
            font-size: 16px;

            button {
                cursor: pointer;
                text-decoration: underline;
                color: $cornflower-blue;
                font-weight: 600;
                background-color: unset;
                border: unset;
                font-family: inherit;
                padding: 0;
            }

            a {
                display: inline;
                cursor: pointer;
                text-decoration: underline;
                justify-content: center;
                font-weight: 600;
            }
        }
    }

    .patient-match {
        p {
            font-size: 16px;
        }

        h3 {
            font-weight: 700;
            font-size: 20px;
        }

        .table-container {
            margin-top: spacing(2.5);

            .table-title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-weight: 600;
                font-size: 18px;
                font-family: "lato";
                margin: spacing(2) 0 0 0;
                padding-bottom: spacing(1);
            }
        }

        .patient-table {
            border-collapse: collapse;
            width: 100%;

            font-size: 16px;

            tbody tr td:nth-child(2) {
                font-weight: 600;
            }

            .MuiOutlinedInput-input {
                padding: spacing(2) spacing(1.5);
            }
        }

        .patient-table td {
            padding: spacing(1.5) spacing(1) spacing(1.5) spacing(2.5);
            text-align: left;
            width: 50%;

            &:first-child {
                font-weight: 400;
            }

            &:last-child {
                font-weight: bold;
            }
        }
        .patient-table tr td {
            border-bottom: 1px solid #0000001f;
            border-top: 1px solid #0000001f;
        }

        .saved.patient-table tr:first-child td,
        .saved.patient-table tr:last-child td {
            border-top: 1px solid $black;
        }
    }

    .patient-additional-details {
        h3 {
            font-weight: 700;
            font-size: 20px;
        }
    }

    .button-wrapper {
        display: flex;
        margin-top: spacing(5);

        button:nth-child(1) {
            margin-right: spacing(0.625);
        }

        button:nth-child(2) {
            margin-left: spacing(0.625);
        }

        a {
            text-decoration: none;
            color: inherit;
        }
    }

    .patient-additional-details {
        position: relative;

        h3 {
            font-weight: 700;
            font-size: 20px;
        }

        p {
            font-size: 16px;
        }

        input:focus {
            border: solid 2px $colour-primary;
        }

        .input-error {
            input {
                border: solid 1px $error-color;
            }
        }

        .edit-button {
            position: absolute;
            right: 0;
            padding: spacing(1) 0 0 0;
        }
        .field.patient {
            p {
                padding: spacing(1.25) 0;
                font-weight: 600;
                color: $black;
                margin: 0;
            }
            div.ui.input {
                width: 100%;
            }
        }

        .errors {
            color: $error-color;
            padding: spacing(0.625) 0 0 spacing(0.25);
            font-size: 14px;
            margin-bottom: 0;
        }
    }

    .dialogue {
        color: $cornflower-blue;
        text-decoration: underline;
        cursor: pointer;
        padding: 0;
        background: none;
        border: none;
    }
}
