@import "mixins";
@import "variables";

.admin-next-steps-modal-content {
    .carousel-content {
        height: spacing(11);
        padding-left: spacing(3);
        padding-right: spacing(3);
    }

    .carousel-nav-button-container {
        background-color: transparent;
        color: $black;

        &:hover,
        &:focus {
            opacity: unset;

            button {
                background-color: unset;
                color: unset;
            }
        }
    }

    .carousel-left-button,
    .carousel-right-button {
        position: relative;
        font-size: 42px;

        &:hover,
        &:focus {
            background-color: transparent;
            color: $bright-gray;
        }
    }

    .carousel-left-button {
        right: spacing(4);
    }

    .carousel-right-button {
        left: spacing(4);
    }

    .carousel-nav-buttons {
        font-size: 40px;
        background-color: transparent;
        color: $black;

        &:hover,
        &:focus {
            background-color: transparent;
            color: $black;
        }
    }

    .carousel-indicator-icons {
        color: $colour-secondary;
    }

    .carousel-active-indicator-icons {
        color: $colour-primary;
    }

    .carousel-indicator-container {
        margin-top: spacing(3);
    }
}
