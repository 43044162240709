@import "mixins";
@import "variables";

.phone-number-registration-container {
    position: relative;
    justify-content: space-between;
    height: 270px;

    .phone-number-registration-form {
        justify-content: space-between;
    }
}

.country-code-control {
    width: 25%;

    .country-code-selector {
        .MuiOutlinedInput-root {
            &.Mui-focused .MuiOutlinedInput-notchedOutline {
                border-color: $colour-primary;
            }
        }
    }
}

.mobile-number-error {
    position: absolute;
    top: 33%;
    align-items: center;
    height: 100%;
    width: 100%;
}

.mobile-number-input {
    width: 70%;
}
