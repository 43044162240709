@import "mixins";
@import "variables";

.flex-center {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.mfa-setup-icon {
    width: 200px;
    margin: auto;
    margin-bottom: spacing(1);
}

.text-button {
    cursor: pointer;
    font-size: 14px;
    text-decoration: underline;
    font-weight: 500;

    &--large {
        @extend .text-button;
        font-size: 16px;
    }

    &__no-underline {
        text-decoration: none;
    }

    &--button-inactive {
        cursor: auto;
    }

    &__heavy {
        font-weight: 800;

        &--blue {
            color: $resonant-blue;
        }

        &--grey {
            color: $gray;
        }
    }
}

.full-width {
    width: 100%;
}

.sentence-case {
    &.MuiButtonBase-root {
        text-transform: none;
    }
}

.otp-form-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
}

.otp-form-instructions {
    word-break: break-word;
}

.otp-input-wrapper {
    display: flex;
    align-content: center;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: spacing(3);
    margin-bottom: spacing(6);
    width: 90%;

    @include max-mobile {
        width: 100%;
    }
}

.otp-input {
    height: spacing(7);
    width: spacing(7);

    input {
        text-align: center;
    }

    .MuiOutlinedInput-root.success .MuiOutlinedInput-notchedOutline {
        border-color: $colour-primary;
        border-width: 2px;
    }
}

.mfa-button-wrapper {
    margin-bottom: 16px;
    margin-top: 16px;
    width: 100%;
}

.float-bottom {
    position: absolute;
    bottom: 0;
}

.resend-code-content {
    @include tablet {
        height: 20vh;
    }

    @include max-mobile {
        height: 40vh;
    }

    @include desktop {
        height: 200px;
    }

    margin-bottom: spacing(2);
    margin-top: spacing(2);
}

.disabled-button {
    .Mui-disabled {
        background-color: #c2e7e3 !important;
        color: black;
    }
}
