@import "variables";
@import "mixins";

.interstitial-container {
    &.MuiContainer-root {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        padding-bottom: spacing(5);
    }
}

.interstitial-paper {
    &.MuiPaper-root {
        border-radius: spacing(1.5);
    }
}

.interstitial-contents {
    display: flex;
    flex-direction: column;
    padding: spacing(4);

    @include tablet {
        width: 60vw;
    }

    @include max-mobile {
        width: 95vw;
    }

    @include desktop {
        width: 600px;
    }
}
