@import "mixins";
@import "variables";

.ui.table td.organisation-cell {
    padding: spacing(2) spacing(4);
}

.organisation-name-chip {
    background-color: $gray-background;
    line-height: spacing(2.25);
    margin-right: spacing(0.75);
    margin-bottom: spacing(0.75);
    padding: spacing(0.5) spacing(1.25);
    border-radius: spacing(2);
    font-size: spacing(1.75);
    font-weight: 400;
    color: $black;
    display: inline-block;
    white-space: nowrap;
}

.copy-email {
    display: flex;
    align-items: center;
}

.link-user-account-label {
    font-weight: 700;
    margin-bottom: spacing(0.5);
}

.link-user-account-sublabel {
    color: $mid-grey;
    font-size: spacing(1.5);
    margin-bottom: spacing(1.75);

    &.edit-mode {
        margin-bottom: spacing(0.5);
    }
}
