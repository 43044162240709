.top-padding {
    padding-top: 15px;
}

.ui.grid.tab {
    padding-left: 30px;
    display: flex;
}

p.tab {
    padding-left: 30px;
}
