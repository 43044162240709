@import "mixins";

.container {
    padding: 5px;
    &.error {
        border: 1px solid;
        border-radius: 8px;
        border-color: #993936;
        background: #fef6f6;
        padding: 5px;
    }
}

.wizzard-container {
    padding-left: 1%;
    padding-right: 1%;
}

.error-message {
    color: #993936;
    margin-bottom: -2px;
    margin-left: 4px;
}

.wizzard-container-title {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.guidance-page-wrapper {
    margin: 20px;
}

@media screen and (max-width: 1024px) {
    .ui.grid .two.column.row.reset-password-grid {
        flex-direction: column;

        & .column {
            width: 100% !important;

            &:last-child {
                margin: 20px auto;
            }
        }
    }
}
