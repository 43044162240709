@import "variables";

.ui.basic.modal.active.qr-code-dialog {
    background: white;
    .header {
        text-align: center;
        color: $black;
    }
    .close.icon {
        color: $black;
    }
    .qr-code {
        width: "60%";
        height: "60%";
        margin-left: "20%";
        margin-right: "20%";
    }
}
