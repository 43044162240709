@import "variables";
@import "mixins";

.custom-message {
    display: flex;
    border-radius: spacing(0.5);
    line-height: spacing(3);
    min-height: spacing(2);
    position: relative;
    margin-bottom: spacing(1);
    margin-top: spacing(1);
    padding: spacing(2) spacing(2.5);
    width: 100%;
    word-break: break-word;

    .message-title {
        font-size: 16px;
        font-weight: 600;

        &__only {
            font-weight: 400;
        }
    }

    &.error {
        background-color: $error-background-darker;
        color: $error-font-smoked-red;
    }

    &.info {
        background-color: $info-background-color;
        color: $info-text-color;
    }
    .MuiButton-root {
        color: $info-text-color;
    }
}
