@import "variables";
@import "mixins";

$footer-offset-mobile: 90px;
$footer-offset-tablet: 71px;
$footer-offset-desktop: 60px;

* {
    &,
    &:before,
    &:after {
        box-sizing: border-box;
    }
}

html,
body,
#root {
    width: 100%;
    height: 100%;
}

body {
    min-width: auto;
}

.main-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: $white;
    overflow: hidden;
    position: relative;

    &.with-static-footer {
        padding: 0;

        .main-segment {
            border-bottom: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        .footer {
            width: auto;
            top: auto;
            left: auto;
            right: auto;
            bottom: auto;
        }
    }

    .main-segment {
        flex: 1;
        display: flex;
        flex-direction: column;
        background-color: $dr-white;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 0;
        margin: 0;

        &.test-page {
            background-color: #ffb6c1;
        }
    }

    .main-segment__empty {
        padding: 0;
        margin: 0;

        @include max-mobile {
            background: $white;
        }
    }
}

.main-header {
    & + .main-segment {
        padding-top: spacing(3);
    }
}

.home-header {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    list-style: none;
}

.main-segment .ui.container.home-screen-wrapper {
    width: 1427px;
    position: relative;

    &.full-height {
        display: flex;
        flex-grow: 1;
        margin-top: -24px;
        align-items: center;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.h1 {
    padding: 24px 0;
    font-weight: 800;
    font-size: 36px;
    line-height: 40px;
}

.h2 {
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;

    @include tablet {
        font-size: 24px;
        line-height: 29px;
    }

    & + & {
        margin-top: 25px;

        @include tablet {
            margin-top: 45px;
        }
    }
}

.h3 {
    margin-bottom: 32px;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
}

.p {
    @include paragraph;
}

.h1,
.h2,
.h3,
.p {
    &.white {
        color: $white;
    }

    &.center {
        text-align: center;
    }
}

.is-heading {
    font-size: 20px;
    line-height: 32px;
    font-weight: bold;

    @include tablet {
        padding: 12px 0;
        font-size: 28px;
        line-height: 36px;
    }

    &,
    &__small {
        margin-bottom: 16px;
        @include tablet {
            margin-bottom: 24px;
        }
    }

    & {
        @include tablet {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
    }

    &__small {
        padding: 9px 0;
        font-size: 18px;
        font-weight: bold;
        line-height: 22px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
}

.is-subtitle {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.3px;
}

.custom-input {
    @include tablet {
        width: 400px;
    }

    p:not(.is-error),
    label {
        @include paragraph;
        letter-spacing: 0.3px;
        color: $light-black;
        padding: 6px 0;
        margin: 0;
    }

    .ui.input {
        display: flex;

        input {
            font-size: 16px;
            border-radius: 0;
        }

        input[type="date"] {
            @include tablet {
                width: 180px;
            }
        }
    }

    .ui.selection.dropdown {
        border-radius: 0;
        font-size: 16px;
        padding: 0.78571429em 2.1em 0.685714em 1em;
    }

    .ui.action {
        .ui.button.labeled {
            display: flex;
            justify-content: center;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.2px;
            background-color: $isle-royale !important;
            border-radius: 0;
            color: $black;
            font-weight: normal;

            @include max-mobile {
                padding: 0 25px !important;
                position: absolute;
                height: 100%;
                right: 0;
            }

            @include tablet {
                min-width: 150px;
            }

            i.icon {
                font-size: 1.5em;
                color: $white;
                background-color: transparent;
            }
        }

        @include max-mobile {
            position: relative;

            .ui.button.icon.labeled {
                font-size: 0;

                i {
                    font-size: 20px;
                }
            }
        }
    }

    .custom-input {
        padding-top: 1.25em;
    }
}

.ui.form {
    .disabled {
        &.field {
            opacity: 1;
        }
        &.input {
            opacity: 0.75;
        }
    }

    &.margin-bottom-2 {
        margin-bottom: spacing(2);
    }
}

.custom-input,
.custom-radio,
.custom-checkbox {
    & + & {
        padding-top: 20px;
    }
}

.custom-checkbox {
    .ui.checkbox {
        input {
            &:checked ~ label:before {
                border: 1px solid $black;
            }
        }

        label {
            padding-left: 50px;
            @include paragraph;

            &:before,
            &:after {
                width: 24px;
                height: 24px;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }

            &:before {
                border: 1px solid $black;
            }

            &:after {
                font-size: 16px;
            }
        }
    }
}

.custom-radio {
    .ui.radio.checkbox {
        label {
            padding-left: 38px;
            @include paragraph;

            @include max-mobile {
                text-align: left;
            }

            &:before,
            &:after {
                width: 20px;
                height: 20px;
            }
        }

        input:checked ~ label {
            font-weight: bold;

            &:before {
                border-color: $black;
            }
        }
    }

    .options {
        display: flex;
        flex-direction: column;
        padding: 10px 35px;

        @include tablet {
            flex-direction: row;
            padding: 20px;
        }

        .ui.radio {
            @include max-mobile {
                padding: 10px 0;
            }
        }

        &:not(.columned):not(.centered) {
            .ui.radio + .ui.radio {
                @include tablet {
                    padding-left: 70px;
                }
            }
        }

        &.columned {
            flex-direction: column;

            .ui.radio {
                padding: 10px 0;

                &:first-child {
                    padding-top: 0;
                }

                &:last-child {
                    padding-bottom: 0;
                }
            }
        }

        &.centered {
            @include tablet {
                .checkbox {
                    display: flex;
                    width: 50%;
                    justify-content: center;
                    margin-right: auto;

                    label {
                        min-width: 125px;
                        text-align: left;
                    }
                }
            }
        }
    }

    .ui.form .p,
    .p {
        margin: 0;
    }
}

.is-required {
    [aria-required],
    p,
    label {
        &:after {
            content: "*";
            color: $error-color;
            padding-left: 5px;
        }
    }
}

.is-error {
    &:not(:empty) {
        @include error;
    }
}

.with-error {
    .ui.input input {
        border-color: $error-color;
    }
}

.buttons-wrapper {
    text-align: center;
    padding: spacing(2) spacing(2) spacing(3);

    &__remote-consent {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @include max-mobile {
            justify-content: center;
        }

        &.at-home-flow {
            justify-content: center;
        }
    }

    .ui.button {
        width: spacing(27);
        max-width: 100%;
        height: 44px;
        margin: 0 auto;

        & + .ui.button {
            margin-bottom: spacing(2);

            @include tablet {
                margin-bottom: 0;
                margin-left: spacing(3);
            }
        }
    }

    &__stacked {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .MuiButtonBase-root {
            margin: spacing(1);
            max-width: 100%;
            width: spacing(27);
        }

        .ui.button {
            & + .ui.button {
                margin: spacing(2);

                @include tablet {
                    margin-left: spacing(2);
                }
            }
        }
    }

    @include max-mobile {
        display: flex;
        flex-direction: column-reverse;
    }

    @include mobile {
        display: flex;
        padding: spacing(2) 0 spacing(3);
    }
}

.buttons-row-wrapper {
    display: flex;
    flex-direction: column;

    @include tablet {
        flex-direction: row;
        justify-content: center;
    }

    .ui.button {
        width: spacing(19);
        height: 44px;
        margin-bottom: 0;

        & + .ui.button {
            margin-top: 20px;

            @include tablet {
                margin-top: 0;
                margin-left: 20px;
            }
        }
    }
}

.is-button-link {
    background: transparent;
    padding: 0;
    margin: 0;
    color: $mid-blue;
    text-decoration: underline;
    border: 0;
    outline: none;
    font-style: inherit;
    cursor: pointer;
}

.is-disabled {
    pointer-events: none;

    &,
    label {
        color: $mid-grey !important;
    }
}

.is-disabled {
    pointer-events: none;
    color: $mid-grey !important;

    label {
        color: $mid-grey !important;
    }
}

.password-matching {
    padding: 24px 0;

    .item {
        @include paragraph;
    }
}

.custom__container {
    @include max-mobile {
        padding: 0;

        .custom__wrapper {
            padding: 0;
            margin: 20px 0 0;
            border: 0;
            border-radius: 0;
        }
    }

    .buttons-wrapper {
        @include max-mobile {
            padding: 0 0 40px;
        }
    }
}

.expired__token {
    margin: auto;
}

.registration__wrapper,
.custom__wrapper {
    background: $white;
    border: 1px solid $mercury;
    border-radius: 3px;

    &,
    &-transparent {
        padding: 20px 12px;
        margin: 20px 0;

        @include tablet {
            padding: 20px 45px;
        }
    }

    &-transparent {
        background-color: transparent;
        border: none;
        border-radius: 0;
    }

    p {
        &:not(.form-field-tip) {
            @include paragraph;

            a {
                @include link;
            }
        }
    }
}

.registration__wrapper {
    padding: 20px 12px;
    margin: 20px 0;

    @include tablet {
        padding: 24px;
    }
}

.custom__container.at-home-flow {
    padding-top: 0;

    .registration__wrapper {
        margin-top: 0;
        padding-top: 0;
    }
}

.animated-dots {
    margin-left: -3px;

    &:after {
        content: " .";
        animation: blinkedDots 1s steps(5, end) infinite;
    }
}

@keyframes blinkedDots {
    0%,
    20% {
        color: rgba(0, 0, 0, 0);
        text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    40% {
        color: currentColor;
        text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    60% {
        text-shadow: 0.25em 0 0 currentColor, 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    80%,
    100% {
        text-shadow: 0.25em 0 0 currentColor, 0.5em 0 0 currentColor;
    }
}
