@import "variables";
@import "mixins";

.ui.page.modals .ui.small.modal {
    &.success-modal {
        .close-modal {
            position: absolute;
            right: -4px;

            p {
                color: $white;
                opacity: 0.7;
                text-transform: uppercase;
                margin-top: 5px;
            }

            button {
                color: $black;
            }
        }

        .modal-header {
            font-weight: bold;
            font-size: 24px;
            color: $black;
            line-height: 28px;
            border-bottom: 0;

            span {
                display: block;
                font-size: 12px;
                text-transform: uppercase;
                font-weight: 100;

                b {
                    font-weight: 900;
                }
            }

            .ui.message {
                font-size: 1rem;
            }
        }

        .modal-text {
            font-weight: bold;
            font-size: 14px;
            line-height: 16px;
        }

        .actions {
            background: none;
            border: none;
            display: flex;

            button {
                margin-left: 0.5em;
            }
        }

        .modal-success-screen {
            height: 400px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .modal-success-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            a {
                color: $black;
                opacity: 0.5;
            }
        }

        .modal-dermatologists-wrapper {
            max-height: 175px;
            min-height: 175px;
            overflow-y: auto;

            .answer {
                max-width: inherit;
            }
        }
    }
}

.confirm-modal-wrapper {
    margin: 20px;
}

.confirm-modal-header {
    font-size: 14px;
    font-weight: bold;
    border-bottom: solid 1px $black;
    margin-bottom: 10px;
}

.no-automated-decision {
    .MuiDialog-paper {
        padding: spacing(4.25) spacing(7) spacing(7);

        div {
            display: flex;
            flex-direction: column;
            align-items: center;

            .MuiDialogTitle-root {
                padding: spacing(2.75) 0 0;
                text-align: center;
                h2 {
                    font-size: 18px;
                    font-weight: bold;

                    @include mobile {
                        font-size: 16px;
                    }
                }
            }

            &.MuiDialogContent-root {
                padding: spacing(2.75) 0 spacing(3.25) 0;
                p {
                    font-size: 16px;
                    @include mobile {
                        font-size: 12px;
                    }
                }
            }

            .MuiDialogActions-root {
                display: flex;
                flex-direction: row;
                justify-content: center;

                @include mobile {
                    flex-direction: column;
                }

                .ui.button {
                    height: 44px;
                }

                .empty-button {
                    width: spacing(12.5);

                    @include mobile {
                        width: spacing(25);
                    }
                }

                .filled-button {
                    width: 200px;
                    margin-right: 0;

                    @include mobile {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}

.modal-dialog-container {
    .MuiDialog-paper {
        min-width: 272px;
        width: 100%;
    }

    .MuiDialog-paperWidthSm {
        max-width: 392px;

        .MuiDialogTitle-root {
            text-align: center;
        }

        .modal-subtitle {
            padding-bottom: spacing(2);

            div {
                text-align: center;
            }
        }

        .MuiDialogActions-root {
            max-width: 100%;
            padding: spacing(1) 0;
            .MuiButtonBase-root {
                width: 100%;
            }
        }
    }

    .MuiDialog-paperWidthMd {
        max-width: 495px;

        .MuiDialogTitle-root {
            text-align: center;
        }

        .modal-subtitle {
            padding-bottom: spacing(2);

            div {
                text-align: center;
            }
        }

        .MuiDialogActions-root {
            max-width: 100%;
            padding: spacing(1) 0;
            .MuiButtonBase-root {
                width: 100%;
            }
        }
    }

    .MuiDialog-paperWidthLg {
        max-width: 598px;

        .MuiDialogTitle-root {
            text-align: left;
        }

        .MuiDialogActions-root {
            display: flex;
            justify-content: center;
            max-width: 50%;
            padding: spacing(1) 0;
            margin: 0 auto;

            .MuiButtonBase-root {
                max-width: fit-content;
            }
        }
    }

    .modal-dialog {
        margin: spacing(4);
        border-radius: 12px;

        .MuiDialogTitle-root {
            padding: 0 0 12px 0;
            position: relative;

            .MuiButton-root {
                position: absolute;
                right: -20px;

                .Mui-focusvisible {
                    background-color: $gray;
                }

                :hover {
                    background-color: none;
                }
            }

            .MuiTypography-root {
                font-size: 20px;
            }
        }

        .modal-subtitle {
            padding-bottom: spacing(2);

            div {
                display: flex;
                flex-direction: column;
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                color: $gray;
            }
        }

        .MuiDialogContent-root {
            padding: spacing(1) 0 0;
            font-size: 16px;
            overflow-y: unset;

            .select-dermatologist-wrapper {
                max-height: 174px;
                overflow-y: auto;

                .row {
                    height: 52px;
                    margin: 0 spacing(0.5) spacing(1) 0;
                    text-align: left;

                    .ui.segment.answer {
                        max-width: 100%;
                        margin: 0;
                    }
                }
            }

            .organisation-switch-wrapper {
                line-height: 24px;
                padding: 0;

                .organisation-name {
                    color: $colour-primary;
                    font-weight: 700;
                }
            }
        }

        .dialog-content {
            display: flex;
            justify-content: center;
            text-align: center;
            padding-bottom: spacing(2);

            .row {
                padding-bottom: 0;
            }
        }

        .icon-container {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: spacing(4);

            i {
                margin: 0;
                color: $black;
                box-shadow: none;
                background-color: $colour-primary;
            }

            .icon-component {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                background-color: $colour-primary;
                padding: 4px;
                min-width: 72px;
                min-height: 72px;

                svg {
                    &.MuiSvgIcon-root {
                        width: 42px;
                        height: 42px;
                    }
                }
            }
        }

        .icon-container:empty {
            display: none;
        }
    }
}
