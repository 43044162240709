@import "mixins";

.ui.label.medical-question-label {
    width: 70px;
    margin-left: -70px;
}

.row.row-margin {
    margin-top: 10px;
}

.long-text-field div {
    width: 300px;
    min-width: 300px;
}

.small-input-text div {
    width: 80px;
}

.organisation-sticky-area {
    background-color: #ffffff;
}

.ui.grid .column.organisation-management-tab-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ui.grid .right.floated.organisation-management-tab-content {
    display: flex;
    justify-content: flex-end;
}

.ui.grid .row.organisation-management-tab-description {
    margin: 0 15px;
    padding-top: 0;
}

.notification-input-block {
    padding-top: 1em;
}

.dermatologists-table {
    font-size: 1em;
    width: 100%;
    margin: 1em 0;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 0.28571429rem;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);

    &.disabled {
        pointer-events: none;
        opacity: 0.5;
    }
}

.dermatologists-table-header,
.dermatologists-table-row {
    p {
        margin: 0;
        font-weight: bold;
    }
}

.dermatologists-table-header {
    display: flex;
    justify-content: space-between;
    background: #f9fafb;
    padding: 0.92857143em 1.5em 0.92857143em 0.78571429em;
    border-bottom: 1px solid rgba(34, 36, 38, 0.15);
}

.dermatologists-table-body {
    max-height: 40vh;
    overflow-y: scroll;
}

.dermatologists-table-row {
    display: flex;
    justify-content: space-between;
    padding: 0.92857143em 0.78571429em;
    border-bottom: 1px solid rgba(34, 36, 38, 0.15);

    p {
        margin: 0;
    }
}

.dermatologist-name {
    width: 40%;
}

.dermatologist-availability {
    width: 20%;
}

.dermatologist-checkbox {
    width: 10%;
    text-align: center;
}

.dermatologist-link {
    width: 10%;
    text-align: center;

    i {
        cursor: pointer;
    }
}

.dermatologists-table-empty {
    margin: 1em;
    text-align: center;
    font-weight: bold;
}

.auto-allocation-row {
    padding: 1.5em 0;

    @include widescreen {
        display: flex;
    }

    p {
        @include widescreen {
            padding-top: 40px;
        }
    }
}

.auto-allocation-radios {
    @include widescreen {
        display: flex;
        justify-content: space-around;
        flex: 1;

        label {
            text-align: center;
        }
    }

    .ui.checkbox {
        display: block;
        padding: 1em 0;

        @include widescreen {
            display: flex;
            padding: 0;
        }

        label {
            font-weight: bold;

            @include widescreen {
                &:before,
                &:after {
                    top: 44px;
                    left: 50%;
                }
            }
        }
    }
}

.auto-allocation-text {
    padding: 1em 0;
}

.notification-row {
    display: flex;
    margin: 1em 0;
    align-items: center;

    .notification-input-label {
        padding-right: 1em;
    }

    .ui.checkbox {
        margin-bottom: -3px;
    }
}

.notification-input-label {
    &,
    &:first-child {
        margin: 1em 0 1em;
        font-weight: bold;
    }
}

.notification-title {
    word-break: break-all;
    line-break: anywhere;
}

.toggle-email-content {
    margin: 0;
    padding: 0.5em 0;
    background-color: #fff;
    border: none;
    cursor: pointer;
    text-decoration: underline;

    &:focus {
        outline: none;
    }
}

.personal-data-wrapper {
    display: flex;
    flex-direction: column;
}

.new-personal-data-field {
    display: flex;
}

.personal-data-header {
    min-height: spacing(7);

    .autofill-patient-details {
        display: flex;
        margin-top: spacing(3);
        padding-bottom: spacing(3);

        div:nth-child(2) {
            display: flex;
            flex-direction: column;
            margin-left: spacing(1.875);

            span {
                font-weight: 400;
                font-size: 14px;
            }
            span:nth-child(2) {
                font-size: 12px;
                opacity: 0.87;
            }
        }
    }
}
