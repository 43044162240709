div.mfa-setting {
    padding-top: 5px;
    padding-bottom: 10px;
}

a.user-settings-link {
    text-decoration: underline;
}

a.user-settings-action-link {
    text-decoration: underline;
    float: right;
}

div.user-settings > h3 {
    font-weight: 400;
}

img.mobile-number-changed-icon {
    padding-top: 10%;
    padding-bottom: 10%;
    margin-left: auto;
    margin-right: auto;
    width: 10%;
}

h3.mobile-number-changed-title {
    text-align: center;
}

button.mobile-number-close-button {
    width: 100%;
}

img.remove-trusted-device-confirmation-icon {
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    width: 10%;
}

h3.remove-trusted-device-confirmation-title {
    text-align: center;
}

h4.remove-trusted-device-confirmation-subtitle {
    text-align: center;
    margin-top: 0px;
}

p.remove-trusted-device-confirmation-text {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
}

.trusted-device-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 5px;
    box-sizing: border-box;
}

.trusted-device-data {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.trusted-device-actions {
    text-decoration: underline;
}

div.last-activity-info {
    color: #dbdcdd;
}
