@import "variables";
@import "mixins";

.ui.grid.grid-dialog-body {
    margin-bottom: 0px;
}

.grid-dialog-body.show-management-option-row {
    .row {
        padding-top: 1px;
        padding-bottom: 5px;
    }
}

.grid-dialog-body {
    .dialog-alert {
        display: flex;
        align-items: center;
        padding-bottom: spacing(1);
        .MuiAlert-message {
            font-size: 14px;
            font-weight: 500;
            overflow: hidden;
        }
    }

    .pds {
        font-weight: 600;
    }

    &--left-align {
        text-align: left;
    }
}

.ui.grid.vertical-space {
    padding-top: 10px;
}

.ui.grid > div.row.no-padding {
    padding: 0px;
}
