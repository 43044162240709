@import "mixins";
@import "variables";

.ui.container.organisation-selection-container {
    max-width: spacing(75) !important;
    max-height: spacing(32);

    & > .ui.segment {
        padding: spacing(5);

        & > .organisation-list {
            max-height: spacing(18.5);
            margin-bottom: spacing(1.5);
            overflow: auto;

            .ui.form {
                max-height: spacing(28);
            }

            .ui.button.segment {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: spacing(1.75);
                padding: 0;
                background-color: $gray-background;
                border-radius: 4px;
                min-height: spacing(8);

                p {
                    padding-left: spacing(2);
                    color: $black;
                    font-size: spacing(2);
                    font-weight: 400;
                }

                .ui.image {
                    display: none;
                    width: spacing(4);
                    height: spacing(4);
                    margin-right: spacing(2);
                }

                &:hover,
                &:focus {
                    background-color: $dark-gray-background;
                    cursor: pointer;
                }
            }

            .ui.button.segment.selected {
                background-color: $colour-secondary;

                .ui.image {
                    display: unset;
                }
            }
        }

        & > .continue-button-container {
            text-align: right;

            & > button {
                width: spacing(17.75);
                background-color: $colour-primary;
                color: $black;

                &:hover,
                &:focus {
                    background-color: $verdigris;
                }
            }
        }
    }
}
