.disc {
    list-style-type: disc;
}

.circle {
    list-style-type: circle;
}

.clinic-location-button-container {
    margin-top: 20px;
}
