@import "mixins";
@import "variables";

.create-user-container {
    &__form-validation {
        color: #993936;
        margin-bottom: -2px;
        margin-left: 4px;
    }

    &__form-container {
        padding: 5px;

        &--error {
            background-color: #fef6f6;
            border: 1px solid #993936;
            border-radius: 8px;
            padding: 5px;
        }
    }
}

.organisation-select {
    .ui.multiple.dropdown > .label {
        border: unset;
        line-height: spacing(2.25);
        padding: spacing(0.5) spacing(1.25);
        border-radius: spacing(2);
        box-shadow: unset;
        background-color: $gray-background;
        font-size: spacing(1.75);
        font-weight: 400;
        color: $black;
        vertical-align: initial;

        & > .delete.icon {
            height: spacing(2);
            width: spacing(2);
            font-size: spacing(1.25);
            border-radius: 50%;
            background-color: $black;
            color: $gray-background;
        }
    }
}
