@import "mixins";

.consentBlock {
    padding-bottom: spacing(3) !important;

    .ui.disabled.checkbox label {
        opacity: 1;
    }

    .indentedCheckbox {
        padding-left: spacing(3);
    }
}
