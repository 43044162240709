@import "variables";
@import "mixins";

.ui.container.reset-password-container {
    flex: 1;
    display: flex;
    align-items: center;
    margin-top: spacing(-3);
    width: spacing(67);
}

.ui.segment.reset-password {
    display: flex;
    flex-direction: column;
    padding: spacing(4.25) spacing(6.75) spacing(2.5);

    & > .ui.form > .password-matching-text {
        font-weight: bold;
        margin-top: 0;
    }

    & > p.reset-password-text {
        font-size: 16px;
        line-height: 22px;
    }

    & > p.sorry-message {
        text-align: center;

        & > a {
            text-decoration: underline;
        }
    }
}

.ui.image.reset-password-icon {
    display: flex;
    width: spacing(7.5);
    margin: auto;
}

.ui.header.reset-password-header {
    text-align: center;
    margin: spacing(1);
}

.deny-list-error-container {
    height: spacing(6.75);

    & > .deny-list-error-text {
        border: 1px solid $light-red;
        border-radius: 5px;
        background-color: $error-background;
        font-size: 12px;
        line-height: 14px;
        padding: spacing(1) spacing(1.75);
        color: $error-color;
    }
}

.reset-button-container {
    display: flex;
    justify-content: center;
}
