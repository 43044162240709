@import "mixins";

.footer {
    margin-top: auto;
    padding: 1rem;
    border-top: 2px solid rgba(34, 36, 38, 0.15);
    background: $dark-white;
    color: $dark-grey;

    .footer-row {
        .copyright,
        .version {
            font-weight: 700;
        }
    }

    .navigation {
        display: flex;
        align-items: center;
        list-style: none;
        margin: 0;
        padding: 0;

        .link {
            color: $dark-grey;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    @include desktop {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .footer-row {
            display: block;

            .copyright,
            .version {
                text-transform: uppercase;
            }

            .copyright {
                padding-right: 2rem;
            }
        }

        .navigation {
            a {
                text-transform: uppercase;
                font-weight: 700;
                padding: 0 1rem;
            }
        }
    }

    @include max-tablet {
        font-size: 14px;

        .navigation {
            padding-top: 5px;

            a {
                padding: 0 12px;

                &:first-child {
                    padding-left: 0;
                }
            }
        }
    }

    @include tablet {
        .footer-row {
            display: flex;
            justify-content: space-between;
        }
    }

    @include max-mobile {
        font-size: 12px;

        .footer-row {
            .version {
                display: block;
            }
        }
    }
}
