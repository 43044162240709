@import "variables";
@import "mixins";

$case-list-cell-border: rgba(34, 36, 38, 0.1);
$border: 1px solid #ccc;

.case-list-actions {
    @include max-mobile {
        .case-list-filters-row {
            display: none;
        }
    }
}

.ui.pointing.secondary.menu.case-list-tabs {
    border-bottom: none;
    flex-wrap: wrap;
}

.ui.table thead th.case-list-header-cell {
    position: relative;
    cursor: pointer;

    &:before {
        background-color: $isle-royale;
        opacity: 0.2;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        content: "";
    }

    p {
        color: $black;
        display: inline;
    }
}

.ui.table thead td.case-list-cog-td.case-list-menu-cell {
    background-color: $table-header;
    cursor: pointer;

    &:hover {
        background: rgba(0, 0, 0, 0.05);
    }

    &.headcol {
        margin-top: -1px;
        height: 47px;
        border-bottom: 1px solid $case-list-cell-border;
        border-top: 1px solid $case-list-cell-border;
        border-radius: 8px;
        border-radius: 0 0.28571429rem 0 0;
        border-right: 0;
    }
}

.case-list-context-menu-button {
    .MuiIconButton-root:hover {
        background-color: unset;
        .icon:hover {
            background-color: #999999 !important;
        }
    }
    .MuiButtonBase-root {
        font-size: 14px;
    }
    .Mui-focusVisible > .MuiIconButton-label > .icon {
        transform: scale(1.1);
    }
}

.case-list-container {
    border: {
        top-right-radius: 0.28571429rem;
        bottom-right-radius: 0.28571429rem;
        right: solid 1px $case-list-cell-border;
        bottom: solid 1px $case-list-cell-border;
    }

    .ui.table {
        @include max-mobile {
            border: none;

            & tr {
                padding: 0;
                box-shadow: none;
                margin-bottom: 10px;
            }
        }
    }

    @include max-tablet {
        overflow-x: auto;
    }

    @include max-mobile {
        margin-top: spacing(2);
    }
}

.ui.table .case-list-menu-cell.headcol {
    position: absolute;
    width: 77px;
    border-left: 0;
    right: 0;
    height: auto;
    display: flex;
    justify-content: center;

    &.empty-list {
        position: relative;
    }

    .ui.dropdown .menu {
        left: auto;
        right: 0;
        top: calc(100% + 5px);
    }
}

.ui.label.case-list-label {
    white-space: nowrap;
}

.ui.label {
    &.case-list-label {
        &:not(.case-list-label-overdue) {
            border: none !important;
            background: transparent !important;
            color: $shark !important;
        }
    }

    &.case-list-label-overdue {
        &,
        &.basic {
            background-color: $yellow !important;
            color: $shark !important;
            cursor: pointer;
            border: none !important;
        }
    }

    &.case-list-action-label {
        &,
        &:not(.case-list-label-overdue) {
            background-color: $isle-royale !important;
            color: black !important;
            font-weight: bold;
            cursor: pointer;
        }
    }
}

.ui.small.active.case-list-loader {
    margin-left: 5px;
}

.ui.header.case-list-total-info {
    text-transform: uppercase;
    opacity: 0.5;
    width: 200px;
    margin-bottom: 0;
}

.ui.button.case-list-load-more-button {
    text-transform: uppercase;
}

.case-list-footer-cell {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

    @include max-mobile {
        flex-direction: column;

        .case-list-load-more-button {
            margin-top: 10px;
        }
    }
}

.case-list-filters-row {
    margin-bottom: 20px;
    width: 100%;
}

.case-list-filters-select {
    .ui.selection.dropdown {
        min-width: auto;
    }

    .custom-select-container .MuiOutlinedInput-root:focus-within .MuiOutlinedInput-notchedOutline {
        border-color: #96c8da;
    }

    .custom-select {
        &.MuiOutlinedInput-root {
            height: 38px;
        }
    }
}

.two.wide.column.case-list-filters-reset {
    display: flex;
    align-items: center;
    text-decoration: underline;
    font-weight: bold;
    padding-top: 37px;

    h3 {
        cursor: pointer;
    }
}

.ui .sixteen.column .input {
    width: 100%;
}

.ui .celled .case-list-menu-cell {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ui .celled .case-list-table-row {
    cursor: pointer;
}

.case_id {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .icon {
        margin: 0 0px 4px;
        padding: 0 4px;
    }
}

.ui.segment.settings-portal {
    right: 20px;
    position: absolute;
    top: 46px;
    z-index: 1000;
    width: 320px;
    overflow-y: auto;
    max-height: 60vh;

    .header {
        margin-bottom: 5px;
    }

    .item {
        cursor: pointer;
        margin: 0 -1rem;
        padding-left: 1rem;
        padding-right: 1rem;

        &:hover {
            background-color: $isle-royale;
        }

        &:first-child {
            padding-top: 0.42857143em;
        }

        &:last-child {
            padding-bottom: 0.42857143em;
        }
    }

    .item .icon {
        margin-right: 10px;
    }

    .case-settings-reset-to-default-button {
        width: 100%;
    }

    .draggable {
        background-color: $isle-royale;
    }
}

.case-history-row {
    display: flex;
    min-width: 20vw;
    border: {
        right: $border;
        top: $border;
    }

    &:last-child {
        border-bottom: $border;
    }

    &:nth-of-type(odd) {
        background-color: #f9f9f9;
    }
}

.case-history-row-empty {
    border: none;
}

.case-history-time {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-left: $border;
    width: 30%;
}

.case-history-date {
    display: block;
    font-size: 16px;
    font-weight: bold;
}

.case-history-hours {
    display: block;
}

.case-history-description {
    width: spacing(38);
    border-left: $border;
    word-break: break-word;
}

.case-history-notification {
    border-left: $border;
    padding: 10px 20px;
}

.case-history-time,
.case-history-description {
    display: flex;
    align-items: center;
    padding: 10px 20px;
}

.case-list-table-wrapper {
    width: calc(100% - 75px);
    overflow-x: auto;
    overflow-y: visible;
    margin-top: 10px;

    &.empty-list {
        max-width: calc(100% - 75px);
        width: fit-content;
    }

    .ui.table {
        border: {
            bottom: 0;
            top-right-radius: 0;
            bottom-right-radius: 0;
        }
    }
}

.case-list-nhs {
    display: block;
    margin: 0;
    padding: 0;
    white-space: nowrap;
}

.ui.grid > .row.case-list-header {
    &__tab-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 1rem;
        padding-right: 1rem;

        & > div {
            &:first-child {
                display: flex;
                align-items: center;

                .ui.header {
                    margin-bottom: 0;
                    margin-right: 1em;
                }
            }

            .case-list-tabs {
                justify-content: flex-end;
                margin-top: 0;
            }
        }
    }

    &__action-button-row {
        display: flex;
        justify-content: flex-end;

        .case-list-header__action-buttons {
            display: flex;
            justify-content: flex-end;

            & > button {
                height: 42px;
                margin-bottom: 0px;
            }
        }
    }

    @include max-mobile {
        &__action-button-row {
            display: none;
        }
    }
}

.case-sas-link {
    display: block;
    max-width: 100px;
    line-height: 1;
    color: rgba(0, 0, 0, 0.87);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    i {
        margin-left: 0.5em;
    }
}

.case-list-container .ui.table .case-list-header {
    @include max-mobile {
        display: none;
    }
}

.case-list-container .ui.table .case-list__cell {
    margin: 0;
    padding: 20px;

    .case-list__header-cell-mobile {
        display: none;
    }

    & .case-list__cell-container {
        border: none !important;
    }

    @include max-mobile {
        display: flex !important;
        flex-direction: row;
        border: solid 1px $case-list-cell-border;
        padding: 0 !important;

        & .case-list__header-cell-mobile,
        & .case-list__cell-container {
            width: 50%;
            height: auto;
            border: solid 1px $case-list-cell-border !important;
            display: flex;
            padding: 10px;

            .icon {
                display: none;
            }
        }

        .case-list__header-cell-mobile {
            display: flex;
            position: relative;
            cursor: pointer;

            &:before {
                background-color: $isle-royale;
                opacity: 0.2;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                content: "";
            }
        }

        p {
            color: $black;
            display: inline;
        }
    }
}

.case-list__header-cell-mobile {
    white-space: nowrap;
    border-left: 1px solid rgba(34, 36, 38, 0.15);
    color: rgba(0, 0, 0, 0.87);

    &:first-child {
        border-left: none;
    }

    &.sorted,
    &.sorted:hover {
        user-select: none;
    }

    &:after {
        font-style: normal;
        font-weight: normal;
        text-decoration: inherit;
        content: "";
        height: 1em;
        width: auto;
        opacity: 0.8;
        margin: 0em 0em 0em 0.5em;
        font-family: $icons-font-family;
    }

    &.ascending:after {
        content: "\f0d8";
    }

    &.descending:after {
        content: "\f0d7";
    }
}
