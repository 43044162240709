@import "variables";
@import "mixins";

$container-desktop-breakpoint: 1199px;
$container-tablet-breakpoint: 992px;
$report-list-cell-border: rgba(34, 36, 38, 0.1);

.main-segment {
    &.remote-model {
        background-color: $dr-white;
        height: 100%;
        overflow: auto;
        margin: 0;

        .ui.message {
            &:last-child {
                margin-top: spacing(-1);
                margin-bottom: 20px;
            }

            &:only-child {
                margin-top: 50px;
            }
        }
    }
}

.remote-model__header {
    padding: 25px 0 spacing(5);
    color: $white;
    text-align: center;
    background: #0d104b;
    margin-bottom: 30px;
    min-height: max(spacing(38), 30vh);

    @include tablet {
        margin-bottom: 50px;
        padding: spacing(5) 0;
    }

    .h1 {
        @include max-mobile {
            width: 250px;
            margin: auto;
        }
    }

    .p {
        @include paragraph;
        max-width: 505px;
        margin: auto;
    }
}

.remote-model__text {
    margin-bottom: spacing(5);

    p {
        @include paragraph;
    }
}

.remote-model__header-row {
    display: flex;
    justify-content: space-between;
}

.logo-sa {
    @include max-mobile {
        height: spacing(5);
    }
}

.logo-organisation {
    height: 75px;

    @include max-mobile {
        height: 54px;
    }
}

.remote-model__copies-header {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    margin-bottom: 25px;
    border-bottom: 1px solid #e6e6e6;

    p {
        margin: 0;
        padding: 0;
        font-size: 24px;
        font-weight: bold;
        line-height: 29px;
        color: #0d104b;
        padding-left: spacing(2);
    }
}

.subtitle {
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
}

.modal-remote-model {
    &.ui.modal {
        @include desktop {
            width: 570px;
        }
    }
}

.remote-model__modal {
    position: relative;
    padding: 20px 25px 35px;
    text-align: center;

    @include tablet {
        padding: 35px 50px 45px;
    }

    &-close {
        margin: 0;
        padding: 1em;
        background-color: transparent;
        border: 0;
        position: absolute;
        top: 0;
        right: 0;

        @include tablet {
            cursor: pointer;
        }

        @include hover {
            img {
                transform: rotate(0.25turn);
                transition: 0.2s;
            }
        }
    }

    img:not(.is-icon) {
        margin: 0 auto 20px;

        @include tablet {
            margin-bottom: 25px;
        }
    }

    ul {
        padding-left: 15px;
    }

    .h2 {
        word-break: break-word;
    }

    .h3 {
        font-weight: 600;
    }

    .h4 {
        font-weight: normal;
        @include paragraph;
    }

    .p {
        text-align: left;
        letter-spacing: 0.3px;
    }

    .buttons-wrapper {
        padding: 20px 0 0;
        display: flex;
        flex-direction: column;

        @include desktop {
            flex-direction: row;
        }

        .ui.button + .ui.button {
            margin-top: 12px;
            margin-left: auto;

            @include desktop {
                margin-top: 0;
                margin-left: 24px;
            }
        }
    }

    .semibold {
        font-weight: 500;
    }

    a {
        color: #2f80ed;
        text-decoration: underline;
    }

    @include max-mobile {
        .h2.check-email-text {
            margin: -20px;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: calc(100vw - 20px);
            overflow: hidden;
            position: relative;
            display: inline-block;
        }
    }
}

.remote-model__modal-questions,
.remote-model__modal-preauth-error {
    display: flex;
    flex-direction: column;
    min-height: 50vh;

    @include tablet {
        min-height: 565px;
    }

    .content {
        flex: 1;
    }

    .h2 {
        margin-bottom: 36px;

        @include max-mobile {
            font-size: 18px;
            line-height: 22px;
            width: 95%;
            margin: 10px auto 32px;
            z-index: 2;
        }
    }

    .buttons-wrapper {
        justify-self: flex-end;
        padding: 0 0 1em;

        &:last-of-type {
            padding: 0;
        }
    }
}

.remote-model__form {
    margin-bottom: 40px;

    .custom-checkbox {
        @include max-mobile {
            margin-top: 22px;
        }
    }

    @include tablet {
        padding: 0 22px;
    }

    @include desktop {
        padding: 0 32px;
        margin-bottom: 50px;
    }

    .custom-radio {
        .options {
            padding: 0;
        }
    }

    .custom-checkbox {
        padding-top: 15px;

        @include tablet {
            padding-top: 22px;
        }

        @include max-mobile {
            &:first-child {
                padding-top: 0;
            }
        }
    }
}

.exception-question {
    @include desktop {
        min-height: 100px;
    }

    & + & {
        @include desktop {
            padding-top: 8px;
        }
    }

    .is-error {
        @include paragraph;
        color: $error-color;

        @include max-mobile {
            text-align: left;
        }

        @include tablet {
            width: 100%;
            position: absolute;
            top: calc(100% - 18px);
            left: 0;
            right: 0;
        }
    }
}

.additional-info {
    padding-top: 35px;
    text-align: left;

    @include tablet {
        padding-top: 55px;
    }
}

.remote-model__review-row {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eaeaea;
    padding-top: 27px;

    @include tablet {
        width: 500px;
        margin: 0 auto;
    }

    & + .buttons-wrapper {
        margin-top: 55px;
    }

    p {
        margin: 0;

        &:last-child {
            padding-left: spacing(2);
            text-align: right;
        }
    }
}

.validate-mobile {
    padding-top: 20px;
    text-align: left;

    .custom-input {
        @include tablet {
            width: 200px;
        }
    }

    .with-error {
        position: relative;
    }

    .is-error {
        position: absolute;
        top: 100%;
        left: 0;
        white-space: nowrap;
    }

    .additional-info {
        padding-top: 45px;
    }
}

.case-creation-description-text {
    text-align: justify;
    margin: 0 40px;

    @include max-mobile {
        margin: 0;
    }
}

.remote-model {
    padding: spacing(3);

    &:first-child {
        margin-top: 20px;
    }

    &.consent {
        .remote-model__form {
            margin-bottom: 25px;
        }

        .custom-checkbox {
            margin-bottom: 40px;
            padding-top: 20px;

            label {
                font-weight: bold;
            }
        }

        .consent-text {
            margin: 0 20px;
        }
    }

    &.kit {
        .remote-model-main-img {
            margin: 15px auto;
        }

        p {
            color: $light-black;
        }

        .remote-model__review-row {
            border-bottom: none;
        }

        .remote-kit-text {
            margin-top: 10px;

            & p {
                margin: 0 auto;
            }

            & p:first-child {
                font-weight: bold;
            }
        }

        .remote-kit-text-below {
            & p:first-child {
                color: $black;
            }
            margin-bottom: 30px;
        }

        .remote-model-kit-dispatch-status {
            padding: 20px;
            border: 15px solid $dr-white;
            min-height: 80%;

            .remote-modal-case-status-active {
                display: flex;
                flex-direction: row;

                @media screen and (max-width: $container-tablet-breakpoint) {
                    .remote-modal-case-status-circle {
                        height: 44px;
                        width: 44px;
                    }
                }

                h2 {
                    display: flex;
                    margin: 0;
                    justify-content: center;
                    margin-left: 20px;
                    max-width: 60%;
                    margin-top: 15px;
                    line-height: 32px;

                    @media screen and (max-width: $container-tablet-breakpoint) {
                        margin-top: 5px;
                    }
                }
            }

            p {
                margin: 10px auto;
                color: $light-black;
                text-align: center;
                font-weight: bold;

                &:last-child {
                    text-align: left;
                    font-weight: normal;
                }
            }

            .button-wrapper {
                display: flex;
                justify-content: center;
                margin-top: 15px;
            }

            @include max-mobile {
                padding: 10px;
            }
        }
    }

    &.lesion-images {
        margin-top: 0;
        padding-top: 0;

        &:first-child {
            .header {
                margin-top: 0;
            }
        }

        .lesion-header {
            display: flex;
            flex-direction: row;
            align-items: center;

            h3 {
                margin: 0;
                font-size: 20px;

                span {
                    white-space: pre-wrap;
                }
            }
        }

        .change-lesion-location-link {
            .ui.header {
                color: $resonant-blue;
                font-size: 14px;
                line-height: 20px;
                text-decoration: underline;
            }
        }

        @include mobile {
            padding-left: 0;
            padding-right: 0;

            .lesion-header {
                flex-direction: column;
                align-items: flex-start;

                h3 {
                    margin-bottom: spacing(1);
                }

                a {
                    margin-left: 0;
                }
            }
        }
    }

    p {
        font-size: 16px;
    }

    & .status p {
        font-size: 14px;

        @media screen and (max-width: $container-desktop-breakpoint) {
            font-size: 12px;
        }
    }

    .h2 {
        font-size: 23px;
    }

    .remote-model-kit-dispatch-status {
        margin-top: spacing(1);

        p {
            @include max-mobile {
                font-size: 14px;
            }
        }
    }

    @include max-mobile {
        &.foldable {
            .ui.header {
                max-width: 80%;
            }
        }
    }

    @include mobile {
        overflow-wrap: break-word;
    }
}

.ui.grid.case-creation-information {
    margin: 0 -10px;
    margin-top: 40px;

    img {
        margin: 0 auto;
    }

    .column:first-child {
        .ui.header {
            margin-top: 33px;

            @include max-mobile {
                margin-top: 20px;
            }
        }

        img {
            margin-top: 5px;
        }
    }

    .column {
        width: calc(33% - 30px);
        border: 1px solid $mercury;
        padding: 20px 0;
        border-radius: 3px;
        margin: 0 15px;

        @include max-tablet {
            margin: 0 10px;
            width: calc(33% - 20px);
        }
    }

    @include max-mobile {
        .row {
            flex-direction: column;

            .column {
                width: calc(100% - 20px);
                padding: 20px;
                margin: 10px;
            }
        }

        .column:last-child img {
            margin-top: 40px;
        }
    }
}

.remote-modal-case-status {
    position: relative;
    display: flex;
    flex-direction: row;
    height: 59px;
    align-items: center;
    margin-top: 40px;
    justify-content: center;

    @include tablet {
        @media screen and (max-width: $container-desktop-breakpoint) {
            margin-top: 60px;
        }
    }
}

.remote-modal-case-status-circle {
    height: 44px;
    width: 44px;
    background-color: $weathered-stone;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        height: 25px;
        width: 25px;
    }
}

.remote-modal-case-status-line {
    height: 1px;
    width: 23px;
    background-color: $weathered-stone;
    display: flex;
    align-self: center;

    @media screen and (min-width: $container-desktop-breakpoint) {
        width: 33px;
    }

    @media screen and (max-width: $container-tablet-breakpoint) {
        width: 12px;
    }

    @include max-mobile {
        width: 25px;
    }

    @include mobile {
        width: 100%;
    }
}

.status {
    display: flex;
    flex-direction: row;

    &.passed {
        .remote-modal-case-status-circle {
            background-color: $isle-royale;
            z-index: 2;
        }

        .remote-modal-case-status-line {
            height: 3px;
            background-color: $isle-royale;
        }

        @include mobile {
            &:first-child {
                flex: 0.75;
            }
        }
    }

    .step {
        display: flex;
        justify-content: center;
        align-items: center;

        p {
            width: max-content;
            top: -40%;
            text-align: center;
            position: absolute;
        }
    }

    @include max-mobile {
        &.mobile-hidden {
            display: none;
        }
    }

    @include mobile {
        flex: 1;
    }
}

.remote-modal-case-status-active {
    .remote-modal-case-status-circle {
        height: 59px;
        width: 59px;
        background-color: $isle-royale;
        z-index: 2;
    }

    img {
        height: 36px;
        width: 36px;
        margin-top: 0;
    }

    p {
        font-weight: bold;
    }

    .remote-modal-case-status-line:first-child {
        height: 3px;
        background-color: $isle-royale;
    }
}

.ui.column.grid .remote-model-location-questions {
    display: flex;
    flex-direction: column;
    margin-left: 20px;

    @include max-mobile {
        margin-left: 0;
    }

    .wide.column {
        width: 100% !important;

        label {
            font-weight: normal;
            margin-bottom: 10px;
            font-size: 16px;
        }

        .short-note-lesion-nsc-location {
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        .short-note-lesion-nsc-location .ui.input,
        .selection.dropdown,
        .custom-select,
        .custom-select-container .MuiFormHelperText-root.Mui-error,
        textarea {
            width: 300px;
            margin-left: 20px;

            @include max-mobile {
                width: 100%;
                margin-left: 0;
            }

            .input {
                width: 100%;
            }
        }
    }
}

.remote-model .questionary-response-row {
    width: 300px;

    @include max-mobile {
        width: 100%;
        flex-direction: column;
    }
}

.remote-model .ui.grid .row .wide.column {
    @include max-mobile {
        width: 100% !important;
    }
}

.token-invalid {
    padding: 2em 0 1em;
}

.recommend-block {
    @include tablet {
        padding: 50px 0;
    }

    .error-message {
        margin-bottom: 20px;
    }

    .h2 {
        font-size: 23px;
    }
}

.recommend-block__copies {
    @include desktop {
        padding: spacing(1);
    }
}

.recommend-block__content {
    background: #ffffff;
    box-shadow: 0px 0px spacing(0.5) rgba(0, 0, 0, 0.25);
    border-radius: spacing(0.5);
    padding: 0 20px;

    .h2 {
        padding-top: 50px;
        width: 80%;
        text-align: center;
        margin: auto;
    }

    @include desktop {
        .buttons-wrapper {
            padding-top: spacing(4);
        }
    }

    @include tablet {
        width: 576px;
        margin: 0 auto;
    }

    @include max-mobile {
        .h2 {
            padding-bottom: spacing(2);
        }
    }
}

.remote-model-youtube_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    @include max-mobile {
        iframe {
            width: calc(100vw - 80px);
        }
    }
}

.recommend-block__submitted {
    padding: spacing(4) 0 spacing(5);
    text-align: center;

    img {
        margin: auto;
    }

    .h2 {
        padding-top: 20px;
    }
}

.rating-block {
    position: relative;
    padding: spacing(4) 0 spacing(5);

    .grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(spacing(5.5), 1fr));
        grid-template-rows: 1fr;
        grid-auto-flow: row;
        justify-content: space-between;
        gap: spacing(0.5);
    }

    .explanation-text {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: spacing(1);
    }

    @include max-mobile {
        .explanation-text {
            p {
                position: absolute;
            }

            p:first-child {
                top: 0;
            }

            p:last-child {
                right: 0;
            }
        }
    }

    @include mobile {
        .grid {
            grid-template-columns: repeat(auto-fit, minmax(spacing(3.5), 1fr));
        }
    }
}

.rating-radio.ui.radio.checkbox {
    &:not(:first-child) {
        margin-bottom: 3px;

        @include tablet {
            padding-left: 3px;
            margin-bottom: 0;
        }
    }

    label {
        display: flex;
        align-items: center;
        justify-content: center;
        width: spacing(6);
        height: spacing(6);
        padding: 0;

        &:before {
            width: 100%;
            height: 100%;
            border: 2px solid $isle-royale;
            border-radius: 4px;
            transition: background-color 0.15s ease-in-out;
        }

        &:after {
            display: none;
        }

        span {
            position: relative;
            z-index: 1;
            font-weight: 500;
            font-size: 20px;
            line-height: 23px;
            transition: 0.15s ease-in-out;
        }
    }

    input:checked + label {
        &:before {
            background-color: $isle-royale;
        }

        span {
            color: $white;
        }
    }

    @include hover {
        label {
            span {
                color: $isle-royale;
            }
        }
    }

    @include mobile {
        label {
            width: spacing(4);
            height: spacing(4);

            span {
                font-size: 16px;
            }
        }
    }
}

.ui.grid.remote-model-lesion-image.qr-code-page {
    display: flex;
    flex-direction: row;

    @include max-tablet {
        .wide.column {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    @media screen and (min-width: $container-tablet-breakpoint) and (max-width: $container-desktop-breakpoint) {
        .remote-model-instructions img {
            width: 175px;
        }
    }
}

.ui.grid > .row {
    &.no-bottom-padding {
        padding-bottom: 0;
    }
}

.ui.grid.remote-model-lesion-images {
    margin: 0;
}

.remote-model-lesion-image-row {
    width: fit-content;
    padding: spacing(2);

    .remote-model-lesion-images {
        display: flex;
        flex-wrap: wrap;
        gap: spacing(4);
        width: 100%;

        .img-preview-wrapper {
            max-width: 100%;
        }
    }

    .ui.header {
        margin-top: spacing(1);
    }
}

.remote-model-lesion-image.case-summary-page {
    width: spacing(28);
    max-width: 100%;
    padding-bottom: calc(100% - 30px);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    border-top: $isle-royale 30px solid;
    border-radius: 3px;
    box-shadow: 0px 0px 22px -5px rgba(34, 60, 80, 0.2);

    button.ui.button {
        background: transparent;
        position: absolute;
        bottom: 5px;
        right: 5px;
    }

    &.retake-image {
        border-top-color: $light-red;

        &:before {
            content: "Retake image";
            position: absolute;
            top: 0;
            top: -25px;
            width: 100%;
            text-align: center;
        }
    }
}

.remote-model-link {
    margin-top: 15px;
    margin-left: 0;

    .ui.header {
        color: $resonant-blue;
        font-size: 18px;
        line-height: 20px;
        text-decoration: underline;
    }
}

.remote-model.ui.container.segment {
    .remote-model-summary-container {
        padding: spacing(2);

        .retake-images-warning {
            p {
                font-size: 1rem;
            }

            .lesion-details {
                p {
                    margin: 0;
                }
            }
        }

        @include mobile {
            padding: spacing(2) 0;
        }
    }
}

.ui.message {
    &.lesion-image-error {
        margin: 0px spacing(2) spacing(2) spacing(2);

        @include mobile {
            margin: spacing(2) 0px;
        }
    }
}

.remote-model-flow-registarion-error {
    font-size: 12px !important;
    font-weight: bold;
    color: $error-color !important;
    margin-top: -10px !important;
}

.referral-recommendation {
    .header {
        display: flex;
        align-items: center;

        .status {
            margin-right: 20px;
        }
    }

    @include max-mobile {
        & ~ .buttons-wrapper {
            .ui.button {
                margin-bottom: spacing(2);
            }
        }
    }

    @include mobile {
        .header {
            flex-direction: column;
        }
    }
}

.remote-model-collapse-div {
    position: absolute;
    top: 0;
    right: 0;
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $isle-royale;
    cursor: pointer;

    .ui.image {
        width: 24px;
        height: 12px;
    }
}

.ui.container.remote-model-container-collapsed {
    padding-bottom: 0;

    .remote-model-collapse-div img {
        transform: rotate(180deg);
    }
}

.lesion-title {
    word-break: break-word;
}

.remote-model-lesion-history {
    padding: spacing(2);

    @include mobile {
        padding: spacing(2) 0;
    }
}

.remote-model-report-error {
    text-align: center;
    color: $guardsman-red;

    &.ui.button {
        border: 2px solid $guardsman-red;
    }
}

.ui.table.assessment-summary-table {
    word-break: break-word;
    > .report-header {
        @include max-mobile {
            display: none !important;
        }
    }
    .item.desktop-row {
        @include max-mobile {
            display: none !important;
        }
    }

    .mobile-row {
        display: none;
        @include max-mobile {
            display: inline;
            .row {
                border: solid 1px $report-list-cell-border !important;
            }
            .row.header {
                border: solid 1px $report-list-cell-border !important;
                word-break: initial;
                background-color: $table-cyan;
            }
        }
    }

    @include max-mobile {
        & thead {
            display: table-header-group !important;
            width: 100%;
        }

        & tbody {
            display: table-row-group !important;
            width: 100%;
        }

        & tr {
            display: table-row !important;
        }

        & tr > th,
        & tr > td {
            display: table-cell !important;
        }
    }
}

.remote-model-instructions {
    display: flex;
    flex-direction: row;

    @include max-mobile {
        flex-direction: column;
    }
}

.patient-note {
    margin: spacing(1) 0;
    display: flex;

    &__title {
        font-weight: 700;
    }

    &__latest {
        background-color: $table-cyan;
        border-radius: spacing(0.5);
        padding: spacing(1);

        h4.header {
            margin-bottom: 0;
        }
    }

    &__text {
        white-space: pre-wrap;
        word-break: break-word;
    }
}

.recaptcha-wrapper {
    margin-top: spacing(4);
}

.remote-model .questionary-item-at-home .questionary-response-row {
    width: 100%;
}

.set-password-additional-info {
    margin-top: spacing(3);
    margin-bottom: spacing(1);
}

.add-new-lesion-message {
    p {
        font-size: 1em;
        text-align: center;
    }
    margin: spacing(2);
}

.kit-delivery-timeline {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: spacing(3.75);
    font-family: Lato;

    .timeline {
        position: relative;
        display: flex;
        flex-direction: row;

        .timeline-item {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            @include max-mobile {
                flex-direction: column;
            }

            &:not(:last-child) {
                margin-right: spacing(12.5);
                @include max-mobile {
                    margin-right: spacing(3);
                }
                @include mobile {
                    margin-right: spacing(2);
                }
            }

            &:nth-child(2)::before,
            &:nth-child(3)::before {
                content: "";
                position: absolute;
                top: 50%;
                left: -90px;
                height: 2px;
                width: 80px;
                transform: translateY(-50%);
                background-color: #bdbdbd;
                @include max-mobile {
                    width: 60px;
                    left: -44px;
                    top: 38%;
                }
                @include mobile {
                    width: 40px;
                    left: -30px;
                    top: 38%;
                }
            }

            span {
                padding-left: 10px;
                @include mobile {
                    font-size: 12px;
                    padding-left: 0;
                }
            }

            .icon-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                height: 40px;
                width: 40px;
                background-color: #e0e0e0;
            }
        }

        .timeline-item-passed {
            @extend .timeline-item;

            .icon-container {
                background-color: $colour-primary;
            }

            &:nth-child(2)::before,
            &:nth-child(3)::before {
                background-color: $colour-primary;
            }
        }
    }
}

.kit-delivery-body {
    font-family: Lato;
    p {
        font-size: 16px;
        font-weight: 400;
        span {
            font-size: 18px;
            font-weight: 600;
        }
    }

    .remote-model-patient-details {
        text-align: center;
        margin-bottom: spacing(2.5);
        p {
            margin: 0;
            font-size: 18px;
            font-weight: 600;
        }
    }
}

.kit-delivery-body-dispatched {
    @extend .kit-delivery-body;

    .tracking {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        span {
            font-size: 16px;
            font-weight: 400;
            opacity: 38%;
        }

        a {
            margin: spacing(1) 0 spacing(5);
            width: 146px;
            height: 36px;
            padding: spacing(1.25);

            &.MuiButton-containedPrimary {
                background-color: #e0e0e0;
                .MuiButton-label {
                    color: $black;
                    font-size: 16px;
                    font-weight: 600;
                    opacity: 100%;
                }
            }
        }
    }
}

.subtext {
    font-size: 14px !important;
    font-weight: 400;
    font-family: Lato;
    opacity: 60%;
    text-align: center;
}

.kit-delivery-buttons-wrapper {
    margin: spacing(4) auto spacing(4);
    width: 250px;
    display: flex;
    justify-content: center;

    a:nth-child(2) {
        margin-left: 20px;
    }
}
