@import "variables";

@mixin paragraph {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    line-height: 24px;
}

@mixin error {
    @include paragraph;
    padding: 3px 0;
    color: $error-color;
}

@mixin link {
    text-decoration: underline;
}

@mixin mobile {
    @media screen and (max-width: #{$mobile}) {
        @content;
    }
}

@mixin max-mobile {
    @media screen and (max-width: #{$tablet - 1}) {
        @content;
    }
}

@mixin tablet {
    @media screen and (min-width: #{$tablet}) {
        @content;
    }
}

@mixin max-tablet {
    @media screen and (max-width: #{$desktop - 1}) {
        @content;
    }
}

@mixin desktop {
    @media screen and (min-width: #{$desktop}) {
        @content;
    }
}

@mixin widescreen {
    @media screen and (min-width: #{$widescreen}) {
        @content;
    }
}

@mixin hover {
    @media (hover: hover) {
        &:hover {
            @content;
        }
    }
}

@function spacing($number) {
    $multiplier: 8;

    @return $multiplier * $number * 1px;
}
